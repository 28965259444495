.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.info {
    margin-right: 20px;
    white-space: nowrap;
}

.planBtn {
    margin-right: 20px;
}

.wrapper.short {
    left: var(--sidebar);
    width: calc(100% - var(--sidebar));
}

.wrapper.short.minimizedSidebar {
    left: var(--sidebar-small);
    width: calc(100% - var(--sidebar-small));
}

.sidebarToggler {
    display: none;
}

.wrapper.short .sidebarToggler {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    outline: none;
    background: none;
    color: var(--gray-700);
    font-size: 20px;
    border: 1px solid var(--gray-300);
    cursor: pointer;
}

.btnTip {
    display: flex;
    position: absolute;
    left: 20px;
}

.sidebarToggler i {
    font-size: inherit;
    color: inherit;
}

.wrapper.short .logoLink {
    display: none;
}
.wrapper:not(.short) .info {
    display: none;
}

.line {
    background: linear-gradient(89.96deg, #D3FFFC 0.27%, #7FC9DE 32.11%, #77B5E4 63.44%, #263E63 96.85%);
    height: var(--line);
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: inline-block;
}

.line.animate {
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.header {
    margin-top: 16px;
    height: var(--header);
    display: flex;
    width: 100%;
    background: var(--snow);
    border-bottom: 1px solid var(--gray-200);
}

.content {
    width: var(--container);
    display: flex;
    margin: 0 auto;
    align-items: center;
    padding: 0 32px 0 72px;
}

.hide {
    display: none;
}

.logoLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoLink img {
    width: 190px;
}

.dropdown {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-700);
}

.avatar {
    margin-right: 12px;
}

.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.backToMTDC {
    margin-right: 15px;
    white-space: nowrap;
}
