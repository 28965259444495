.typo {
    font-family: 'Inter', sans-serif;
    color: var(--gray-900);
    margin: 0;
    font-weight: 400;
    letter-spacing: -0.02em;
}


.h1 {
    font-size: 48px;
    line-height: 60px;
}

.h2 {
    font-size: 36px;
    line-height: 44px;
}

.h3 {
    font-size: 30px;
    line-height: 38px;
}

.h4 {
    font-size: 24px;
    line-height: 32px;
}

.h5 {
    font-size: 20px;
    line-height: 30px;
}

.h6 {
    font-size: 18px;
    line-height: 28px;
}

.p, .div {
    font-size: 14px;
    line-height: 20px;
}

.small {
    font-size: 12px;
    line-height: 18px;
}

.bold {
    font-weight: 700;
}

.semi {
    font-weight: 600;
}

.medium {
    font-weight: 500;
}
