.wrapper {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.fieldWithRadiosAndInputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field.withoutBorder {
    border-bottom: none!important;
}

.fullInput {
    width: 100%;
}

.field.last {
    border-bottom: none;
    margin-bottom: 0;
}

.field .input {
    width: calc(50% - 10px);
    margin-bottom: 20px;
}

.field .input.shortLabel > label {
    white-space: pre-wrap;
}


.field.twoInputsFieldWithHelp {
    width: 100%;
}
.field.twoInputsFieldWithHelp .input {
    width: calc(50% - 20px);
    margin-bottom: 0;
    padding-bottom: 14px;
}

.pl20 {
    padding-left: 26px;
    box-sizing: border-box;
}

.field.twoInputsField {
    align-items: stretch;
}

.field.twoInputsField .input {
    width: calc(50% - 10px);
    margin-bottom: 0;
    justify-content: space-between;
    padding-bottom: 14px;
}


.field.threeInputsField .input {
    width: calc(33% - 10px);
    margin-bottom: 0;
    padding-bottom: 14px;
}


.underRadioInputsField {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.underRadioInputsField .input {
    width: calc(33% - 10px);
    margin-bottom: 0;
    margin-right: 20px;
    padding-bottom: 14px;
}

.underRadioInputsField .input:first-child {
    margin-left: 26px;
}
.underRadioInputsField .input:last-child {
    margin-right: 0;
}


.selectWrapper .select {
    width: 100%;
    margin-bottom: 0;
}

.selectWrapper {
    width: 400px;
}


.compensations {
    display: flex;
    flex-direction: column;
}

.compensation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer btn {
    width: 200px;
}
