.text {
    margin-bottom: 32px;
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.addWrapper button {
    margin-right: 8px;
}
