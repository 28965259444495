.wrapper {
    display: flex;
    flex-direction: column;
}

.plan {
    width: 100%;
    margin-bottom: 4px;
}

.link {
    width: 100%;
}

.person {
    border: none;
    background: none;
    display: flex;
    box-shadow: none;
    padding: 10px 10px 10px 26px;
    border-radius: 6px;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
}

.title {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.person.withLink {
    padding: 0;
}

.person.withLink a {
    color: inherit;
    font-size: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 26px;
    text-align: left;
}

.person:before {
    position: absolute;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 50%;
    display: inline-block;
    background: var(--teal-500);
    top: 50%;
    transform: translateY(-50%);
    left: 9px;
}


.person.active {
    background: var(--gray-50);
}

.placeholder {
    padding: 14px 10px 12px 10px;
    text-align: center;
}

.remove {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
}

.person:hover {
    background: var(--gray-50);
}

.name {
    color: var(--teal-600);
    padding: 4px 12px;
    background: var(--teal-50);
    margin-left: 8px;
    border-radius: 16px;
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    max-width: 150px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list {
    list-style: none;
    padding: 0 0 0 12px;
}

.year {
    display: flex;
    box-shadow: none;
    padding: 10px 10px 10px 26px;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
}

.year.active {
    background: var(--gray-50);
}

.year:hover {
    background: var(--gray-50);
}
