/*INPUT*/
.input {
    width: 100%;
    padding: var(--input-paddings);
    background: var(--snow);
    border: 1px solid var(--gray-300);
    box-shadow: var(--input-shadow);
    border-radius: var(--br);
    font-weight: 400;
    outline: none;
    font-size: 16px;
    transition: border var(--animation), box-shadow var(--animation), background var(--animation);
    will-change: border, box-shadow, background;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
}

.tagsFieldWrapper :global .input-help {
    top: 38px;
}

.input.pureInput {
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
}

.input.pureInput:focus, .input.pureInput:active {
    box-shadow: none;
    border: none;
}

.input::placeholder {
    color: var(--gray-500);
}

.input:focus, .input:active, .input.select.open {
    box-shadow: var(--input-focus-shadow);
    border: 1px solid var(--primary-300);
}

.input[disabled], .input[disabled]:active, .input[disabled]:focus, .input.disabled, .input.disabled:active, .input.disabled:focus {
    background: var(--gray-50);
    border: 1px solid var(--gray-300);
    box-shadow: var(--input-shadow);
    cursor: not-allowed!important;
}

.input.pureInput[disabled], .input.pureInput[disabled]:active, .input.pureInput[disabled]:focus, .input.pureInput.disabled, .input.pureInput.disabled:active, .input.pureInput.disabled:focus {
    border: none;
    background: none;
    box-shadow: none;
    cursor: not-allowed!important;
}

.input.hasError {
    border: 1px solid var(--red-300);
}

.input.hasError:focus, .input.hasError:active, .input.hasError.select.open {
    box-shadow: var(--input-error-focus-shadow);
    border: 1px solid var(--red-300);
}

/*INPUT WRAPPER*/
.inputWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.inputWrapper.withPreIcon:not(.pureWrapper) .input {
    padding-left: 40px;
}

.inputWrapper.withPostIcon:not(.pureWrapper) .input {
    padding-right: 40px;
}

.icon {
    position: absolute;
    pointer-events: none;
    transition: color var(--animation);
    will-change: color;
}

.input[disabled] + .icon.passwordIcon {
    cursor: not-allowed;
    pointer-events: none;
}

.icon.passwordIcon {
    pointer-events: all;
    cursor: pointer;
}

.input + .icon {
    color: var(--gray-500)!important;
}

.input:not(:placeholder-shown) + .icon {
    color: var(--gray-900);
}

.icon.preicon {
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
}

.icon.posticon {
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

/*TEXTAREA*/
.input.textarea {
    resize: vertical;
    height: var(--textarea-min-height);
    min-height: var(--textarea-min-height);
    padding: var(--textarea-paddings);
}

.input.textarea[disabled] {
    resize: none;
}

/*SELECT*/

.selectWrapper {
    width: 100%;
    position: relative;
}

.input.select {
    position: relative;
    padding-right: 34px;
    cursor: pointer;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input.select.placeholder {
    color: var(--gray-500);
}

.chevron {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    pointer-events: none;
}

.selectWrapper {
    display: flex;
    width: 100%;
}

.list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 320px;
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.1s ease-in-out;
    overflow-y: auto;
    box-shadow: var(--dropdown-shadow);
    margin-top: 4px;
    background: var(--snow);
    list-style: none;
    padding: 16px 6px;
    z-index: 3;
    box-sizing: border-box;
    border: 1px solid var(--gray-200);
    border-radius: var(--br);
}

.list.hiddenList {
    opacity: 0;
    pointer-events: none;
}

.selectItem {
    display: flex;
    align-items: center;
    font-family: var(--font);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-900);
    padding: var(--dropdown-li-padding);
    margin-bottom: 4px;
    border-radius: var(--br);
    cursor: pointer;
}

.selectItem:hover, .selectItem.activeSelectItem {
    background: var(--gray-50);
}

.selectItem.disabledOption, .selectItem.disabledOption:hover {
    background: none;
    cursor: not-allowed;
    color: var(--gray-500)
}

.selectWrapper.pureSelect .chevron {
    display: none;
}

.selectWrapper.pureSelect .input.select {
    border: none!important;
    box-shadow: none!important;
}


/*TOGGLER*/

.togglerWrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
}

.toggler {
    display: flex;
    width: 44px;
    min-width: 44px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 12px;
    transition: background var(--animation);
    will-change: background;
    background: var(--gray-100);
}

.point {
    width: 20px;
    height: 20px;
    pointer-events: none;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background: var(--snow);
    transition: transform var(--animation);
    will-change: transform;
}


.togglerWrapper.invert {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.togglerWrapper.invert .toggler {
    margin-left: 8px;
    margin-right: 0;
}

.togglerCheckbox:checked + .toggler {
    background: var(--primary-700);
}

.togglerWrapper.green {
    align-items: center;
    margin-bottom: 20px;
}

.togglerWrapper.green .togglerCheckbox:checked + .toggler {
    background: var(--teal-400);
}

.togglerCheckbox:checked + .toggler .point {
    transform: translateX(20px);
}

.togglerCheckbox[disabled] + .toggler, .togglerCheckbox[disabled]:checked + .toggler {
    background: var(--gray-100);
    cursor: not-allowed;
}


/*CHECKBOX*/

.customCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: var(--snow);
    border: 1px solid var(--gray-300);
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    cursor: pointer;
    transition: background var(--animation), border var(--animation);
    will-change: background, border;
    margin-right: 8px;
}

.customCheckbox .check {
    opacity: 0;
    display: inline-block;
    color: var(--primary-700)
}

.togglerWrapper .checkboxLabel {
    margin-bottom: var(--field-xs-mb);
}


.togglerWrapper .checkboxLabel.inField {
    margin-bottom: 0;
    padding: 0;
}

.togglerCheckbox:checked + .customCheckbox {
    background: var(--primary-100);
    border: 1px solid var(--primary-600);
}

.togglerCheckbox:checked + .customCheckbox .check {
    opacity: 1;
}

.togglerCheckbox[disabled] + .customCheckbox, .togglerCheckbox[disabled]:checked + .customCheckbox {
    background: var(--gray-100);
    border: 1px solid var(--gray-300);
    color: var(--gray-300);
    cursor: not-allowed;
}

.togglerCheckbox[disabled] + .customCheckbox .check {
    color: inherit;
}


/*RADIO*/
.customRadio {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--snow);
    border: 1px solid var(--gray-300);
    border-radius: 50%;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    cursor: pointer;
    transition: background var(--animation), border var(--animation);
    will-change: background, border;
    margin-right: 8px;
}

.customRadio .check {
    display: none;
    color: var(--primary-700)
}

.togglerRadio:checked + .customRadio {
    background: var(--primary-100);
    border: 1px solid var(--primary-600);
}


.radioDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    opacity: 0;
    background: var(--primary-600);
}

.togglerRadio:checked + .customRadio .radioDot {
    opacity: 1;
}

.togglerRadio[disabled] + .customRadio, .togglerRadio[disabled]:checked + .customRadio {
    background: var(--gray-100);
    border: 1px solid var(--gray-300);
    color: var(--gray-300);
    cursor: not-allowed;
}

.togglerRadio[disabled] + .customRadio .radioDot {
    background: var(--gray-600);
}

.radioInGroup {
    margin-bottom: 0;
}


.radios {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
}

.radios.inlineRadios {
    flex-direction: row;
    align-items: center;
}

.radios.inlineRadios > * {
    margin-right: 72px;
}

.radios > * {
    margin-bottom: 20px;
}

/*COMMON*/
.hidden {
    display: none;
}



/*DATEPICKER*/


.datepicker {
    box-sizing: border-box;
}

.calendarIcon {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 18px;
    pointer-events: none;
    transform: translateY(-50%);
}


.datepicker :global .react-datepicker__triangle:after {
    border-bottom-color: var(--snow)!important;
}



:global .react-datepicker-popper {
    z-index: 2!important;
}


.datepicker :global .react-datepicker__day--today {
    background-color: var(--primary-700)!important;
    border-radius: 5px!important;
    color: var(--snow) !important;
}
.datepicker :global .react-datepicker__time-container {
    border-left: 1px solid var(--gray-300) !important;
}

.datepicker :global .react-datepicker__day--selected {
    background-color: var(--primary-700)!important;
    border-radius: 5px!important;
    color: var(--snow) !important;
}


.datepicker :global li.react-datepicker__time-list-item--selected {
    background-color: var(--primary-700)!important;
    border-radius: 0!important;
    color: var(--snow) !important;
}

.datepicker :global .react-datepicker__triangle:before {
    border-bottom-color: var(--gray-300) !important;
}


.datepicker :global .react-datepicker__header {
    background: var(--snow) !important;
    border-bottom: 1px solid var(--gray-300)!important;
}

:global .react-datepicker {
    box-shadow: var(--datepicker-shadow) !important;
    border-radius: var(--br) !important;
    font-family: var(--font) !important;
    border: 1px solid var(--gray-100) !important;
}

:global .react-datepicker__triangle {
    display: none!important;
}

:global .react-datepicker__header {
    background: var(--snow)!important;
    padding: 0 24px!important;
    border-bottom: 0!important;
}
:global .react-datepicker__current-month {
    padding: 24px 0 18px!important;
    color: var(--gray-700)!important;
    font-family: var(--font)!important;
    font-weight: 600!important;
    font-size: 16px!important;
    line-height: 24px!important;
}

:global .react-datepicker__navigation:hover *::before  {
    border-color: var(--gray-700) !important;
}

:global .react-datepicker__navigation-icon:before {
    border-color: var(--gray-500) !important;
    border-width: 2px 2px 0 0 !important;
}

:global .react-datepicker__navigation--previous {
    left: 24px !important;
    top: 20.5px !important;
}

:global .react-datepicker__navigation--next {
    right: 24px !important;
    top: 20.5px !important;
}

:global .react-datepicker__day-name, :global .react-datepicker__day {
    padding: 12px 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--gray-700) !important;
}



:global .react-datepicker__day.react-datepicker__day--outside-month {
    color: var(--gray-400) !important;
}

:global .react-datepicker__day--disabled {
    color: var(--gray-300) !important;
}

:global .react-datepicker__day {
    font-weight: 400 !important;
}

:global .react-datepicker__day:hover {
    border-radius: 20px !important;
    background: var(--gray-50) !important;
}

:global .react-datepicker__day--selected, :global .react-datepicker__day--selected:hover {
    border-radius: 20px !important;
    background: var(--gray-700) !important;
    color: var(--snow) !important;
}

:global .react-datepicker__day--today, :global .react-datepicker__day--today:hover, :global .react-datepicker__day--keyboard-selected, :global .react-datepicker__day--keyboard-selected:hover {
    border-radius: 20px !important;
    background: var(--gray-100) !important;
    color: var(--gray-700) !important;
}

.datepickerHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 10px;
    align-items: center;
}

.datepickerHeaderSelects {
    flex: 1 100%;
    display: flex;
    align-items: center;
}

.datepickerHeaderSelectWrapper {
    width: 50%;
    align-items: center;
    margin-bottom: 0;
}

.datepickerHeaderSelect:hover, .datepickerHeaderSelect:active {
    color: var(--primary-700);
}

.datepickerHeaderSelect {
    width: 100%;
    font-weight: 500;
}

.datepickerArrow {
    cursor: pointer;
    color: var(--gray-700);
}

/*AUTOCOMPLETE*/
.input.select.autocomplete {
    padding: var(--input-paddings);
    cursor: auto;
    padding-left: 40px;
}

.list.autocompleteList {
    display: none;
}

.list.autocompleteList:hover {
    display: block;
}

.input.autocomplete:focus + .list.autocompleteList {
    display: block;
}



.selectWrapper.withPostIcon .posticon {
    pointer-events: all;
    cursor: pointer;
}
.selectWrapper.withPostIcon .input.select.autocomplete {
    padding-right: 40px;
}

.selectItem.emptyAutocompleteItem:hover {
    background: var(--snow);
}


.selectItem.emptyAutocompleteItem {
    background: var(--snow);
    cursor: auto;
    justify-content: center;
}

.slider {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sliderTrack {
    border-radius: 4px;
    height: 8px;
    background: var(--gray-200);
}

.sliderTrack:first-child {
    background: var(--teal-500);
}

.sliderTrack.sliderTrackError:first-child {
    background: var(--red-500);
}

.sliderDragger {
    display: flex;
    outline: none;
    flex-direction: column;
    align-items: center;
    width: 27px;
    cursor: grabbing;
    transform: translate3d(0, 4px, 0);
}

.sliderDragger.sliderDraggerError .sliderDraggerSpot {
    border: 1.5px solid var(--red-500);
}

.sliderDraggerSpot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1.5px solid var(--teal-500);
    background: var(--snow);
    margin-bottom: 8px;
}

.sliderDraggerValue {
    position: absolute;
    top: 27px;
    left: 50%;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font);
    font-weight: 500;
    transform: translateX(-50%);
    color: var(--gray-900);
}

.sliderWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.sliderWrapper.withErrors {
    padding-bottom: 6px;
}

.sliderInputWrapper {
    margin-left: 32px;
    position: relative;
}

.sliderInputPostfix {
    position: absolute;
    color: var(--gray-500);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font);
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

.sliderInput {
    height: 44px;
    width: 90px;
    box-sizing: border-box;
    padding: 10px 32px 10px 14px;
    color: var(--gray-500);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 8px;
}

.tag {
    font-size: 12px;
    color: var(--snow);
    background: var(--teal-400);
    border-radius: 6px;
    padding: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deleteBtn {
    font-size: 12px;
    color: var(--snow);
    margin-left: 4px;
    cursor: pointer;
}

.tagsWrapper {
    position: relative;
    flex-direction: column;
}

.tagsWrapper .list {
    top: 42px;
}

.tagsInput {
    display: flex;
    position: relative;
}

.togglerLabel {
    padding-bottom: 0;
}

.pureField {
    padding: 0;
}
.pureField > div {
    margin: 0;
}

.newSelectWrapper {
    display: flex;
    width: 100%;
}

.newSelect {
    width: 100%;
    padding: var(--input-paddings);
    background: var(--snow);
    border: 1px solid var(--gray-300);
    box-shadow: var(--input-shadow);
    border-radius: var(--br) !important;
    font-weight: 400;
    outline: none;
    cursor: pointer !important;
    font-size: 16px;
    transition: border var(--animation), box-shadow var(--animation), background var(--animation) !important;
    will-change: border, box-shadow, background;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    height: 42px;
}
.newSelect.newSelectMenuPure {
    border: none!important;
    box-shadow: none!important;
}

.newSelectFocused {
    box-shadow: var(--input-focus-shadow) !important;
    border: 1px solid var(--primary-300) !important;
}
.newSelect.newSelectError {
    border: 1px solid var(--red-300) !important;
}
.newSelect.newSelectError.newSelectFocused {
    box-shadow: var(--input-error-focus-shadow) !important;
}

.newSelectMenu {
    padding: 16px 0;
    box-sizing: border-box;

}

.newSelectMenuItem {
    display: flex;
    align-items: center;
    font-family: var(--font);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: var(--snow);
    color: var(--gray-900);
    padding: var(--dropdown-li-padding);
    cursor: pointer!important;
    transition: background var(--animation);
}

.newSelectMenuItem:hover, .newSelectMenuItem.newSelectMenuItemChosen, .newSelectMenuItem.newSelectMenuItemChosen:hover {
    background: var(--primary-100);
}

.newSelectMenuItem.newSelectMenuItemActive, .newSelectMenuItem.newSelectMenuItemActive:hover {
    background: var(--primary-200);
}

.newSelectMenuWrapper {
    z-index: 5!important;
    box-shadow: var(--dropdown-shadow)!important;
    margin-top: 4px!important;
    background: var(--snow)!important;
    border: 1px solid var(--gray-200)!important;
    border-radius: var(--br)!important;
}

.newSelectPlaceholder {
    color: var(--gray-500);
    font-family: var(--font);
    font-weight: 500;
    font-size: 16px;
}

.newSelectContainer {
    padding: 0;
    margin: 0;
}
.newSelectChevronWrapper > div {
    padding: 0;
}
