.subcategoryItem {
    width: 100%;
    position: relative;
    margin-bottom: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    box-shadow: none;
    border-radius: 6px;
    transition: background var(--animation);
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    font-family: var(--font);
    color: var(--gray-900);
    line-height: 24px;
}

.subcategoryItem.greenCell {
    padding: 10px 10px 10px 26px;
}


.subcategoryItem.active, .subcategoryItem:hover {
    background: var(--gray-50);
}

.greenCell:before {
    position: absolute;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 50%;
    display: inline-block;
    background: var(--teal-500);
    top: 50%;
    transform: translateY(-50%);
    left: 9px;
}

.creationDisabledText {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.placeholder {
    padding: 14px 10px 12px 10px;
    text-align: center;
}

.mt-4 {
    margin-top: 4px;
}
