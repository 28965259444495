.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.placeholder {
    padding: 14px 10px 12px 10px;
    text-align: center;
}

.item {
    border: none;
    background: none;
    display: flex;
    box-shadow: none;
    padding: 10px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
}

.capitilize {
    text-transform: capitalize;
}

.remove {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 8px;
    transform: translateY(-50%);
}
.copy {
    position: absolute;
    top: 50%;
    right: 38px;
    transform: translateY(-50%);
}

.item.active, .item:hover {
    background: var(--gray-50);
}

.name {
    color: var(--teal-600);
    padding: 4px 12px;
    background: var(--teal-50);
    margin-left: 8px;
    border-radius: 16px;
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    max-width: 150px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}
