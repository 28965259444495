.messageWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 0!important;
    justify-content: center;
}

.messageWrapper.closing .message {
    animation-name: fadeOut;
    animation-play-state: running;
}

.message {
    margin: 20px 0;
    max-width: 760px;
    width: 80%;
    padding: 24px 70px 24px 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    background: var(--primary-25);
    text-align: center;
    animation-name: bounceInUp;
    animation-duration: 0.3s;
    position: relative;
    box-sizing: border-box;
    animation-fill-mode: both;
}

.messageText {
    color: var(--primary-700);
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.closeMessage {
    display: flex;
    position: absolute;
    top: 24px;
    right: 36px;
    cursor: pointer;
}

@keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0) scaleY(5);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0) scaleY(3);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
