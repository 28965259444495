.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    padding-top: calc(var(--header) + var(--line));
    height: 100%;
    min-height: calc(100vh - var(--header) - var(--line));
}

.lock {
    transform: rotateZ(180deg);
}

.lockText {
    color: var(--gray-600);
    text-align: center;
    font-size: 14px;
}

.lockFooter {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
}

.lockFooter .button {
    flex: 50%;
}
.lockFooter .button button {
    width: 100%;
}

.lockFooter .button:first-child {
    margin-right: 6px;
}

.lockFooter .button:last-child {
    margin-left: 6px;
}

.childContainer {
    flex: 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background: var(--bg);
}

.childWrapper {
    position: relative;
    flex: 1 100%;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.childWrapper :global .page-wrapper {
    min-height: calc(100vh - var(--header) - var(--line) - 64px);
}

.content.minimizeSidebar {
    padding-left: var(--sidebar-small);
}

.sidebarWrapper {
    width: var(--sidebar);
    display: flex;
    position: fixed;
    flex-direction: column;
    top: var(--line);
    left: 0;
    height: calc(100vh - var(--line));
    background: var(--snow);
    padding: 24px;
    z-index: 11;
    border-right: 1px solid var(--gray-200);
    box-sizing: border-box;
    overflow-y: auto;
}

.sidebarWrapper.blockScroll {
    overflow-y: hidden;
}

.draggable {
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: ew-resize;
    right: -3px;
    transition: background var(--animation), width var(--animation);
}

.draggable.dragging {
    background: rgba(16, 24, 40, 0.1);
    width: 2px;
}

.sidebarWrapper.minimizeSidebar {
    width: var(--sidebar-small);
    padding: 20px;
    overflow-y: initial;
}
.sidebarWrapper.minimizeSidebar .search {
    display: none;
}

.search {
    margin-bottom: 0;
    padding-bottom: 0;
}

.search.withError {
    padding-bottom: 20px;
}

.search > div {
    margin: 0 0 6px;
}

.sidebarWrapper.minimizeSidebar .logo {
    display: none;
}

.sidebarWrapper .logoXS {
    display: none;
}

.sidebarWrapper.minimizeSidebar .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebarWrapper.minimizeSidebar .logoXS {
    display: inline-block;
}

.logo {
    width: 190px;
}

.header {
    margin-top: 32px;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--gray-200);
}

.searchBtn {
    margin-bottom: 0;
}

.steps {
    margin-top: 24px;
    flex: 1 100%;
}

.stepsTitle {
    padding: 0 12px;
    text-transform: uppercase;
}

.tip {
    flex-direction: column;
}

.headerTip {
    flex-direction: column;
    margin-bottom: 16px;
}

.footerTip {
    flex-direction: column;
    margin-bottom: 0;
    display: none;
}

.greenFooterLink {
    margin-left: 32px;
}

.footerTip.showTip {
    display: flex;
}

.searchBtnTip {
    flex-direction: column;
    margin-bottom: 0;
}


.list {
    padding: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
}

.step {
    display: flex;
    flex-direction: column;
}

.chevron {
    margin-right: 8px;
    width: 20px;
    transition: transform var(--animation);
    min-width: 20px;
    color: var(--primary-500);
}

.stepHeader {
    display: flex;
    flex-direction: row;
    padding: 8px 12px;
    cursor: pointer;
    transition: background var(--animation);
    will-change: background;
    align-items: center;
    border-radius: 6px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--primary-300);
    background: var(--primary-25);
}

.stepHeader.active .chevron {
    transform: rotateZ(90deg);
    color: var(--snow);
}

.stepHeader.opened:not(.active) .chevron {
    transform: rotateZ(90deg);
}

.stepIcon {
    width: 26px;
    margin-right: 14px;
    color: var(--primary-500);
}

.stepIcon i {
    color: inherit;
}

.stepLabel {
    color: var(--gray-700);
    white-space: nowrap;
    flex: 1 100%;
}

.stepHeader:hover {

    background: var(--primary-200);
}

.stepHeader.active {
    background: var(--primary-700);
    border: 1px solid var(--primary-700);
    color: var(--snow);
}

.stepHeader.active .stepLabel, .stepHeader.active .stepIcon {
    color: var(--snow) !important;
}


.stepHeader.active .grantCount {
    border: 1.5px solid var(--snow);
}

.grantCount {
    width: 24px;
    min-width: 24px;
    height: 22px;
    border-radius: 16px;
    border: 1.5px solid var(--primary-600);
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    padding: 2px 8px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font);
}

.step.shortStep .stepHeader {
    justify-content: center;
    width: 40px;
    height: 40px;
}

.step.shortStep .stepHeader .stepIcon {
    margin-right: 0;
}
.step.shortStep .grantCount, .step.shortStep .stepLabel, .step.shortStep .chevron {
    display: none;
}

.step.shortStep .minimizedGrantCount {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--teal-400);
    position: absolute;
    border: 1.5px solid var(--snow);
    bottom: 5px;
    right: 8px;
}
.cards {
    display: flex;
    flex-direction: column;
    padding: 4px 0 4px 34px;
    position: relative;
}

.cards:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: calc(100% - 16px);
    background: var(--gray-200);
    position: absolute;
    top: 8px;
    left: 23px;
}

.sidebarWrapper.minimizeSidebar .stepsTitle, .sidebarWrapper.minimizeSidebar .cards {
    display: none;
}

.sidebarWrapper.minimizeSidebar .header {
    padding-bottom: 24px;
}

.sidebarWrapper.minimizeSidebar .steps {
    margin-top: 24px;
}

.footer {
    display: flex;
    flex-direction: column;
    background: var(--primary-50);
    border-radius: 8px;
    padding: 20px 16px;
}

.footer.greenFooter {
    margin-bottom: 8px;
    background: var(--teal-50);
}
.footer.greenFooter.mobileFooter {
    display: none;
}


.sidebarWrapper.minimizeSidebar .footer.greenFooter.mobileFooter {
    display: flex;
}
.sidebarWrapper.minimizeSidebar .footer .footerTitle,
.sidebarWrapper.minimizeSidebar .footer .footerText,
.sidebarWrapper.minimizeSidebar .footer .greenFooterLink,
.sidebarWrapper.minimizeSidebar .footer .preview {
    display: none;
}

.minimizedHelp {
    display: none;
}
.sidebarWrapper.minimizeSidebar .footer {
    padding: 0;
    background: none;
}
.sidebarWrapper.minimizeSidebar .footer  .minimizedHelp.greenHelp {
    border: 1px solid var(--teal-300);
    background: var(--teal-100);
}
.sidebarWrapper.minimizeSidebar .footer  .minimizedHelp {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 12px;
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid var(--primary-300);
    background: var(--primary-25);
}

.footerTitle {
    color: var(--primary-700);
    margin-bottom: 4px;
}
.greenFooterTitle {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font);
    color: var(--teal-500);
}
.greenFooter .greenFooterTitle.last {
    margin-bottom: 0;
}

.footerIcon {
    margin-right: 12px;
}

.help {
    margin-left: 8px;
    color: var(--teal-500);
    transform: rotateZ(180deg);
    cursor: pointer;
}

.mailIcon {
    margin-right: 8px;
}

.footerText {
    color: var(--primary-600);
    margin-bottom: 16px;
}

.preview {
    display: flex;
    margin-top: 12px;
    padding: 0;
    overflow: hidden;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    position: relative;
    background: none;
}

.preview:after {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 48px;
    content: '';
    background: url("/src/images/play.svg");
    transition: background ease 0.3s;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.preview:hover:after {
    background: url("/src/images/play_hover.svg");
}

.preview:active:after {
    background: url("/src/images/play_active.svg");
}

.preview img {
    border-radius: 6px;
    max-width: 100%;
}


.stepWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}


.minimizedGrantCount {
    display: none;
}


.grouped {

}

.groupedHeader {
    display: flex;
    font-family: var(--font);
    font-style: normal;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-700);
}

.groupedHeader .chevron {
    margin-left: 12px;
}
.groupedHeader.openedGroup .chevron {
    transform: rotateZ(-180deg);
}

.feedbackText {
    color: var(--gray-600);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
}

.feedbackText ol {
    margin: 0;
}

.feedbackSection {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 8px;
    margin-bottom: 20px;
}

.feedbackTitle {
    color: var(--gray-600);
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
}
