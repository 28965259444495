.title {
    color: var(--gray-900);
    font-family: var(--font);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.wrapper {
    padding-bottom: 32px;
    margin-bottom: 24px;
}

.wrapper.show {
    background: var(--gray-50);
}

.titleWrapper {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}

.btn {
    font-size: 24px;
}

.btn.arrow :global .btn-icon {
    color: var(--gray-400) !important;
}

.body {
    color: var(--gray-500);
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
}
