.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 12px;
}

.subtitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}


.subtitle {
    font-weight: 700;
}

.gray {
    color: var(--gray-600);
    font-weight: 400;
}

.tables {
    display: flex;
    flex-direction: column;
}

.table {
    margin-bottom: 48px;
}
