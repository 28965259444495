

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}
.gray {
    color: var(--gray-600);
    font-weight: 400;
}

.text {
    margin-bottom: 24px;
    color: var(--gray-900);
    text-align: justify;
}

.btnWrapper {
    display: flex;
    align-items: center;
}

.mrb {
    margin-left: 10px;
}

.formWrapper {
    margin-top: 24px;
}

.formBtnsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
}

.formBtnsWrapper .clear {
    margin-right: 22px;
}

.btnFormWrapper {
    display: flex;
    margin-top: 32px;
    justify-content: center;
}

.linkText {
    color: var(--teal-400);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}


.link {
    display: flex;
}

.li {
    margin-bottom: 8px;
    color: var(--gray-600);
}

.list {
    padding-left: 18px;
    margin-bottom: 24px;
}

.list li::marker {
    color: var(--gray-600);
}

.modalText {
    margin-top: 32px;
    color: var(--gray-600);
    text-align: justify;
}

.modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
}

.modalFooter button {
    flex: 1;
}

.modalFooter button:not(:last-child) {
    margin-right: 12px;
}
