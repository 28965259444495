.wrapper {

}

.title {
    margin-bottom: 4px;
}

.subtitle {
    color: var(--gray-600);
    margin-bottom: 24px;
}

.planWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    min-height: 715px;
    border-radius: 16px;
    border: 1px solid var(--primary-200);
    background: var(--snow);
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.planIconWrapper {
    display: flex;
    border-radius: 28px;
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border: 10px solid var(--primary-50, #F5F7FF);
    background: var(--primary-100, #EBF0FF);
}

.planIcon {
    font-size: 28px;
    color: var(--primary-600);
}

.planImg {
    width: 22px;
    height: 22px;
}

.planWrapper.winWrapper {
    background: var(--primary-800);
    width: 420px;
    min-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 365px;
    margin-top: 30px;
    padding: 260px 24px 32px 24px;
}

.winImage {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -20%, 0);
}

.slider {
    margin-bottom: 48px;
}

.list {
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
}

.listTitle {
    margin-bottom: 24px;
}

.listItem {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--teal-500);
    margin-right: 12px;
}

.sectionTitle {
    color: var(--gray-900);
    margin-bottom: 24px;
}

.listItem p {
    color: var(--gray-600);
}

.univercity {
    margin-right: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 130px;
}

.univercity img {
    max-width: 100%;
}

.planTitleWrapper {
    padding: 64px 24px 32px 24px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.00) 100%);
}

.winTitle {
    color: var(--snow);
    text-align: center;
    font-weight: 600;
    margin-bottom: 12px;
}

.winText {
    color: var(--snow);
    margin-bottom: 64px;
    text-align: center;
}

.winSum {
    font-size: 48px;
    color: var(--snow);
    text-align: center;
}

.planTitle {
    color: var(--primary-900);
    text-align: center;
    font-weight: 600;
}

.features {
    padding: 0 24px 32px 24px;
    position: relative;
    flex: 1 100%;
}

.features.emptyFeatures {
    text-align: center;
    color: var(--gray-600);
    padding: 0 44px 32px 44px;
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.features:after {
    content: '';
    position: absolute;
    width: calc(100% - 48px);
    height: 1px;
    display: inline-block;
    bottom: 0;
    left: 24px;
    background: var(--gray-200);
}

.featuresTitle {
    margin-bottom: 16px;
    color: var(--gray-600);
    font-size: 14px;
    font-weight: 600;
}

.features ul {
    list-style: none;
    padding: 0 0 32px 0;
}

.features ul li {
    color: var(--gray-600);
    font-family: var(--font);
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-bottom: 8px;
}

.featureIcon {
    color: var(--teal-500);
    margin-right: 8px;
}

.planFooter {
    padding: 32px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.price {
    color: var(--gray-600);
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 16px;
}

.dummyPrice {
    color: var(--gray-600);
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 57px;
    display: flex;
    align-items: center;

    line-height: 20px;
}

.winSection {
    flex: 1 100%;
}

.winFooter {
    padding: 24px 0;
    width: 280px;
    border-top: 1px solid var(--primary-500);
}

.winFooterText {
    color: var(--gray-100);
    text-align: center;
}

.help {
    width: 250px!important;
}

.info {
    padding: 24px 32px;
    margin-bottom: 0!important;
    max-width: 900px;
}

.info:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
}

.feedback {
    display: flex;
    padding: 64px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    background: var(--primary-50);
    align-self: stretch;
    color: var(--primary-900);
    height: 280px;
    box-sizing: border-box;
    width: 380px;
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.subscription {
    width: 100%;
    margin-top: 24px;
    padding: 24px;
    border-radius: 12px;
    max-width: 900px;
    border: 1px solid var(--gray-200);
    background: var(--snow);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.subscriptionTop {
    display: flex;
    flex-direction: column;
}

.line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.line.last {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--gray-200);
}

.subscriptionBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
}

.gray {
    color: var(--gray-500);
}

.plans {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 48px;
    padding-top: 24px;
}

.infoTitle {
    font-size: 16px;
    color: var(--gray-600);
    font-weight: 500;
    margin-bottom: 8px;
}

.infoText {
    margin-bottom: 12px;
    color: var(--gray-600);
}

.plansSlider {
    width: calc(100% - 420px);
}

.planContainer {
    padding: 30px 40px 0 0;
}

.infoList {
    padding-left: 18px;
    margin-bottom: 24px;
}

.infoList li {
    color: var(--gray-600);
    padding: 16px 0;
    border-bottom: var(--gray-200) 1px solid;
    font-family: var(--font);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.payBtn {
    border-radius: 8px;
    border: 0 solid var(--gray-300);
    background: linear-gradient(90deg, #30D5C8 0.06%, #2B619B 99.92%);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
    width: auto;
    color: var(--snow);
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    align-items: center;
}

.payBtn:hover {
    background-size: 140% 100%;
    cursor: pointer;
    animation: gradient 1s ease infinite;
}
.icon {
    margin-left: 8px;
    color: var(--snow);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
