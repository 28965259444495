

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}
.gray {
    color: var(--gray-600);
    font-weight: 400;
}
