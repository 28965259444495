.wrapper {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 6px;
    display: inline-flex;
    overflow: visible;
}

.icon {
    cursor: help;
    width: 16px;
    height: 16px;
}

.wrapper.show .content {
    display: flex;
    opacity: 1;
    pointer-events: all;
}

.icon:hover + .content, .icon.hover + .content {
    display: flex;
    opacity: 1;
    pointer-events: all;
}

.icon:hover + .content.right, .icon.hover + .content.right, .wrapper.show .content.right {
    transform: translateY(-50%) translateX(10px);
}
.icon:hover + .content.left, .icon.hover + .content.left, .wrapper.show .content.left {
    transform: translateY(-50%) translateX(-10px);
}

.content, .content.right {
    background: var(--primary-1000);
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--animation), transform var(--animation);
    will-change: opacity, transform;
    font-size: 12px;
    line-height: 18px;
    color: var(--snow);
    z-index: 3;
    padding: 12px;
    border-radius: var(--br);
    width: auto;
    min-width: 100px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(5px);
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}


.content:before {
    border-right: 6px solid var(--primary-1000);
    border-top: 6px solid transparent;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-bottom: 6px solid transparent;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5px;
}

.content.left {
    transform: translateY(-50%) translateX(-5px);
    left: auto;
    right: 100%;
}

.content.left:before {
    border-right: none;
    border-left: 6px solid var(--primary-1000);
    left: auto;
    right: -5px;
}
