.wrapper {
    width: 360px;
    display: flex;
    flex-direction: column;
    padding-top: 96px;
    align-items: center;
    margin: 0 auto;
}

.icon {
    color: var(--primary-600);
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background: var(--primary-100);
    border-radius: 50%;
    border: 10px solid var(--primary-50);
    margin-bottom: 24px;
}

.iconWrapper.green {
    background: var(--teal-100);
    border: 10px solid var(--teal-50);
}

.iconWrapper.green .icon {
    color: var(--teal-600);
}

.title {
    color: var(--gray-900);
    margin-bottom: 12px;
    font-weight: 600;
    text-align: center;
}

.childWrapper {
    width: 100%;
}

.subtitle {
    color: var(--gray-600);
    margin-bottom: 32px;
    text-align: center;
}

.subtitle.red {
    color: var(--error-600);
}

.back {
    margin-top: 32px;
}
