.footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalText {
    margin-bottom: 20px;
    color: var(--gray-600);
    line-height: 24px;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalFooter button:first-child {
    margin-right: 12px;
}

.foreignBtn {
    text-align: left;
}

.foreignBtn.red, .foreignBtn.red:hover, .foreignBtn.red:focus {
    color: var(--error-500);
}

.foreignBtn.green, .foreignBtn.green:hover, .foreignBtn.green:focus {
    color: var(--success-500);
}
