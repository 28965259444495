.hint {
    font-weight: 500;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 12px;
}

.field.last {
    border-bottom: none;
}

.field.twoInputFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.field.twoInputFields .input {
    width: calc(50% - 8px);
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
