
.field {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-200);
}

.field.twoInputsField {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.field.twoInputsField .input {
    width: calc(50% - 10px);
    justify-content: space-between;
    margin-bottom: 0;
}
.formFooter {
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.placeholder {
    width: 100%;
    padding: 48px 72px;
    background: var(--teal-50);
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 120px;
}

.placeholder p {
    color: var(--teal-500);
}

.field.last {
    border-bottom: none;
    padding-bottom: 0;
}

.lastForm {
    margin-top: 72px;
}

.lastForm .title {
    margin-bottom: 20px;
}

