.mb16 {
    margin-bottom: 16px;
}
.mb20 {
    margin-bottom: 20px;
}

.mb0 {
    margin-bottom: 0;
}

.pb0 {
    padding-bottom: 0;
}

.yearsFormWrapper {
    display: flex;
    margin-top: 14px;
    flex-direction: column;
    width: 100%;
}

.yearField {
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
}

.yearCheckbox {
    white-space: nowrap;
    width: 15%;
}

.yearInput {
    margin-left: 8px;
    margin-bottom: 0;
    width: 35%;
}

.yearInput.yearLongInput {
    width: 50%;
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
