.footer {
    display: flex;
    justify-content: center;
}

.footer button:not(:last-child) {
    margin-right: 12px;
}


.footer button {
    width: 220px;
}

.textarea {
    margin-bottom: 0;
}

.placeholder {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.placeholder.head {
    margin-bottom: 32px;
}

.titleText {
    color: inherit;
    font-weight: 600;
}

.titleIcon {
    color: inherit;
    margin-right: 12px;
}

.last {
    margin-bottom: 0;
    padding-bottom: 0;
}

.title {
    color: var(--warning-700);
    display: flex;
    align-items: flex-start;
}

.uncovered {
    margin-top: 4px;
    padding-left: 52px;
    margin-bottom: 0;
}

.uncovered .li, .uncovered li {
    color: var(--warning-700);
    font-size: 14px;
}

.appliedWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 20px;
}
.appliedWrapper ul {
    margin: 12px 0 20px;
}
.applied {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.appliedTitle {
    font-weight: 500;
}

.accepted {
    margin-left: 8px;
    font-size: 12px;
    display: inline-flex;
    font-weight: 500;
    align-items: center;
    font-family: var(--font);
    color: var(--success-700);
    border-radius: 16px;
    background: var(--success-50);
    padding: 2px 6px;
}

.acceptedIcon {
    margin-right: 4px;
    color: var(--success-500);
}
