.wrapper {
    position: relative;
}

.trigger {
    border: none;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    outline: none;
    flex-direction: row;
    align-items: center;
}

.chevron {
    margin-left: 10px;
    transition: transform var(--animation);
    will-change: transform;
}

.trigger.active .chevron {
    transform: rotateZ(180deg);
}

.trigger[disabled] {
    cursor: not-allowed;
}

.link {
    font-weight: 500;
    padding: 15px 18px;
    font-size: 14px;
    color: var(--gray-700);
    display: flex;
    align-items: center;
}

.list {
    position: absolute;
    top: 100%;
    min-width: 150px; /*???*/
    right: 0;
    max-height: 320px;
    overflow-y: auto;
    box-shadow: var(--dropdown-shadow);
    margin-top: 4px;
    background: var(--snow);
    list-style: none;
    padding: 0;
    z-index: 3;
    box-sizing: border-box;
    border: 1px solid var(--gray-200);
    border-radius: var(--br);
}

.listItem {

}

.listItemContent {
    font-family: var(--font);
    font-weight: 500;
    font-size: 14px;
    margin: 0 6px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    line-height: 24px;
    color: var(--gray-700);
    border-radius: var(--br);
    cursor: pointer;
}

.listItem.withDivider {
    border-bottom: 1px solid var(--gray-200);
}

.icon {
    color: var(--gray-700);
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
}

.listItem:hover {
    background: var(--gray-50);
}
