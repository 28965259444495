.field {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 12px;
}

.field.last {
    border-bottom: none;
}

.field.twoInputFields, .field.threeInputFields, .field.fourInputFields {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.field.twoInputFields.top, .field.threeInputFields.top, .field.fourInputFields.top {
    align-items: flex-start;
}

.field.twoInputFields .input {
    width: calc(50% - 8px);
}

.field.threeInputFields .input {
    width: calc(33.33% - 8px);
}


.field.fourInputFields .input {
    width: calc(25% - 8px);
}


.field.twoInputFields.fieldWithHelps .input {
    width: calc(50% - 20px);
}


.field.threeInputFields.fieldWithHelps .input {
    width: calc(33% - 20px);
}


.field.fourInputFields.fieldWithHelps .input {
    width: calc(25% - 20px);
}

.input {
    width: 50%;
}

.input.full {
    width: 100%;
}

.input > * {
    margin-bottom: 0;
}
