.text {
    margin-bottom: 20px;
    color: var(--gray-600);
}

.season {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.seasons {

}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.seasonTitle {
    margin-bottom: 20px;
}

.season:last-child {
    border-bottom: none;
}

.footer {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
}

.seasonFooter {
    display: flex;
}

.seasonFooter button:not(:last-child) {
    margin-right: 12px;
}

.clear {
    display: flex;
    margin-left: 12px;
    flex: 1;
    justify-content: flex-end;
}

.modalFooter {
    display: flex;
    justify-content: center;
}
.modalFooter button:not(:last-child) {
    margin-right: 12px;
}
