.card {
    width: 100%;
    display: flex;
    height: 40px;
    flex-direction: row;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 4px;
    align-items: center;
    position: relative;
    transition: background var(--animation), opacity ease-in-out 0.1s;
}

.title {
    margin-left: 8px;
    font-weight: 500;
    color: var(--gray-900);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card:hover {
    background: var(--primary-200);
}

.icon {
    color: var(--gray-500);
}

.body {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    position: relative;
}

.body:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: calc(100% - 16px);
    background: var(--gray-200);
    position: absolute;
    top: 8px;
    left: 14px;
}

.card:hover .icon {
    color: var(--gray-700);
}

.titleWrapper {
    flex: 1 100%;
    padding-right: 16px;
    overflow: hidden;
}


.chevron {
    cursor: pointer;
}


.menu {
    cursor: pointer;
}

.wrapper {
    position: relative;
}

.menuWrapper {
    position: absolute;
    top: 0;
    left: 100%;
}

.card.active {
    background: var(--primary-700);
}

.card.open:not(.active) {
    background: var(--primary-50);
}

.card.open .chevron {
    transform: rotateZ(90deg);
}

.card.open:not(.active) .icon, .card.open:not(.active) .title {
    color: var(--gray-700);
}

.card.active .icon, .card.active .title {
    color: var(--snow);
}

.activeLink {

}

.link {
    border: none;
    background: none;
    display: flex;
    box-shadow: none;
    padding: 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    transition: background var(--animation);
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
    line-height: 20px;
    font-family: var(--font);
    align-items: center;
    position: relative;
    color: var(--gray-700);
}

.finalLink {
    width: 100%;
}

.link:hover {
    background: var(--primary-200);
}
