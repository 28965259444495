.info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--gray-700);
}

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
