.icon {
    color: var(--gray-500);
    text-align: center;
}

.icon.disabled {
    cursor: not-allowed;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    height: 40px;
    min-height: 40px;
    margin-right: 12px;
}
.iconWrapper .icon {
    color: inherit;
}

.iconWrapper.success {
    background: var(--success-100);
    color: var(--success-600);
    border: 6px solid var(--success-50);
}

.iconWrapper.warning {
    background: var(--warning-100);
    color: var(--warning-600);
    border: 6px solid var(--warning-50);
}

.iconWrapper.error {
    background: var(--error-100);
    color: var(--error-600);
    border: 6px solid var(--error-50);
}

.iconWrapper.info, .iconWrapper.default {
    background: var(--primary-100);
    color: var(--primary-600);
    border: 6px solid var(--primary-50);
}
