.text {
    margin-bottom: 16px;
    color: var(--gray-600);
}

.textWrapper {
    margin-bottom: 24px;
}

.text:last-child {
    margin-bottom: 0;
}
