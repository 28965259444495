
.social {
    margin-bottom: 8px;
    width: 100%;
}

.socialIcon {
    height: 24px;
    margin-right: 12px;
}
