.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    max-width: 400px;
    padding-right: 30px;
    box-sizing: border-box;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    height: 40px;
    min-height: 40px;
    margin-right: 12px;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    outline: none;
    cursor: pointer;
}

.icon {
    color: inherit;
}

.iconWrapper.success {
    background: var(--success-100);
    color: var(--success-600);
    border: 6px solid var(--success-50);
}

.iconWrapper.warning {
    background: var(--warning-100);
    color: var(--warning-600);
    border: 6px solid var(--warning-50);
}

.iconWrapper.error {
    background: var(--error-100);
    color: var(--error-600);
    border: 6px solid var(--error-50);
}

.iconWrapper.info, .iconWrapper.default {
    background: var(--primary-100);
    color: var(--primary-600);
    transform: rotateZ(180deg);
    border: 6px solid var(--primary-50);
}

.content {
    display: flex;
    flex-direction: column;
}

.content p {
    margin: 0;
}

.content p.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-900);
    margin-bottom: 4px;
    font-family: var(--font);
}

.content p.message {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font);
    color: var(--gray-600);
}
