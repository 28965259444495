
.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.consultantsField {
    margin-bottom: 0;
}

.consultantsSection {
    margin-bottom: 16px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    row-gap: 8px;
    border-bottom: 1px solid var(--gray-200);
}

.consultantsList {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
