.mb16 {
    margin-bottom: 16px;
}
.mb20 {
    margin-bottom: 20px;
}

.mb0 {
    margin-bottom: 0;
}

.pb0 {
    padding-bottom: 0;
}

.footer {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
}

.title {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
}

.title span {
    font-size: 20px;
    line-height: 30px;
}

.flexAlignEnd {
    align-items: end!important;
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
