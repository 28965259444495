.hint {
    margin-bottom: 20px;
}

.footer {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.title {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
}

.title span {
    font-size: 20px;
    line-height: 30px;
}
