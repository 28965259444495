
.tableWrapper {
    width: 100%;
    overflow-x: auto;
    border-radius: 12px;
    border: 1px solid var(--gray-200);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.tableWrapper.modalTableWrapper {
    margin-bottom: 32px;
}

.tableWrapper.modalTableWrapper .table thead td:first-child,
.tableWrapper.modalTableWrapper .table thead td:last-child {
    border-radius: 0;
}


.table {
    border-spacing: 0;
    width: 100%;
}

.table td {
    padding: 12px 24px;
}


.table thead td {
    border: 1px solid var(--gray-200);
    border-top: none;
    font-size: 12px;
    font-family: var(--font);
    font-weight: 500;
    color: var(--gray-600);
    text-align: center;
    background: var(--gray-50);
}

.table thead td:first-child {
    border-left: none;
    border-radius: 12px 0 0 0;
}
.table thead td:last-child {
    border-right: none;
    border-radius: 0 12px 0 0;
}

.table tbody tr td {
    border: 1px solid var(--gray-200);
    font-size: 12px;
    background: var(--snow);
    font-family: var(--font);
    color: var(--gray-900);
}


.table tbody tr td.mint {
    background: var(--teal-100);
    position: relative;
    padding-right: 44px;
}


.table tbody tr td.value.inputWrapper {
    padding: 0;
}

.table tbody tr td.value {
    font-size: 14px;
    text-align: center;
}

.table tbody tr td.value.forbidden {
    cursor: not-allowed;
}


.table tbody tr td.name {
    font-size: 12px;
    font-weight: 500;
}


.table tbody tr td.disabled {
    color: var(--gray-400)
}

.table tbody tr td.greenName {
    background: var(--gray-50);
    color: var(--teal-500);
    font-size: 12px;
}

.table tbody tr td.greenName a {
    font-size: inherit;
}

.table tbody tr.hide {
    display: none;
}


.table tbody tr td.grey {
    background: var(--gray-50);
}

.table tbody tr td.primary {
    background: var(--primary-100);
}

.table tbody tr td.icon {
    color: var(--gray-600);
    text-align: center;
    position: relative;
    cursor: pointer;
}

.table tbody tr td.icon i {
    color: inherit;
}

.table tbody tr td.icon.dummy:after {
    display: none;
}
.table tbody tr td.icon:after {
    /*display: none;*/
    /*content: '';*/
    /*width: 2px;*/
    /*background: var(--gray-600);*/
    /*height: calc(100% + 2px);*/
    /*bottom: -1px;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*position: absolute;*/
}

.table tbody tr td.icon.first:after {
    /*height: 36%;*/
}

.table tbody tr td.icon.first.closed:after {
    /*display: none;*/
}

.table tbody tr td.icon.last:after {
    /*height: calc(50% + 1px);*/
    /*bottom: 50%;*/
}
.table tbody tr td.icon.last:before {
    display: none;
    /*content: '';*/
    /*height: 2px;*/
    /*width: 25%;*/
    /*background: var(--gray-600);*/
    /*bottom: 50%;*/
    /*left: 50%;*/
    /*position: absolute;*/
}

.table tbody tr td.chbox {
    text-align: center;
}

.table tbody tr td:first-child {
    border-left: none;
}

.table tbody tr td:last-child {
    border-right: none;
}

.yearWrapper {
    display: flex;
    flex-direction: column;
}

.yearTitle {
    white-space: nowrap;
    padding: 12px 0;
    border-bottom: 1px solid var(--gray-200);
}

.yearCell {
    padding: 0!important;
}

.year {
    display: flex;
    align-items: center;
}

.year span {
    padding: 12px 6px;
    flex: 1;
}

.year span:first-child {
    border-right: 1px solid var(--gray-200);
}

.table td.black {
    background: var(--gray-900);
    color: var(--snow);
    text-transform: uppercase;
    white-space: nowrap;
}

.blackText {
    display: flex;
}

.table td.big {
    font-size: 14px;
}
.table td.green {
    background: var(--teal-500);
    color: var(--snow);
}


.footer {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    padding-bottom: 40px;
}

.inputField {
    display: flex;
    padding: 0;
    margin: 0;
}
.input {
    font-size: 14px!important;
    text-align: center;
    padding: 12px 24px!important;
}

.input :global .bm-input-icon {
    font-size: 14px!important;
}

.commentIcon {
    color: var(--teal-500);
    position: absolute;
    right: 24px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    font-size: 20px!important;
}
.commentIcon.active {
    color: var(--warning-300);
}

.tip {
    align-items: center;
    justify-content: center;
}

.modalBtn {
    margin-left: 12px;
    color: var(--teal-500, #14B4A7);
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    text-transform: none;
    align-items: center;
    cursor: pointer;
}

.modalBtnIcon {
    margin-left: 8px;
    color: var(--teal-500);
}

.modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalSubtitle {
    color: var(--gray-600);
    margin-bottom: 20px;
}

.modalFooter button:not(:last-child) {
    margin-right: 12px;
}
.modalFooter button {
    flex: 1;
}

.modalTableTitle {
    padding: 20px 24px;
    font-size: 18px;
    font-weight: 600;
    color: var(--gray-900);
    border-bottom: 1px solid var(--gray-200);
}

.warning {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.warningText {
    color: inherit;
    font-weight: 600;
}

.warningIcon {
    color: inherit;
    margin-right: 12px;
}

.warningTitle {
    color: var(--warning-700);
    display: flex;
    align-items: flex-start;
}

.uncovered {
    margin-top: 4px;
    padding-left: 52px;
    margin-bottom: 0;
}

.uncovered .li, .uncovered li {
    color: var(--warning-700);
    font-size: 14px;
}

.link {
    margin: 0 3px;
}
