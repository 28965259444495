.wrapper {
    display: flex;
    max-width: 350px;
    width: 300px;
    flex-direction: column;
}
.title {
    margin-bottom: 12px;
    color: var(--snow);
}

.checks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.check {
    display: flex;
    align-items: center;
    border-radius: 16px;
    transition: color var(--animation), background var(--animation);
    will-change: color, background;
    padding: 4px 12px;
    margin: 7px 4px 7px 0;
    color: var(--gray-400);
    background: var(--gray-600);
}

.icon {
    color: var(--snow);
    margin-right: 6px;
}

.checkText {
    color: inherit;
}

.check.pass {
    color: var(--snow);
    background: var(--teal-400);
}


.wrapper.mobile {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.wrapper.mobile .title{
    color: var(--gray-600);
}
.wrapper.mobile .check:not(.pass) {
    background: var(--gray-100);
    color: var(--gray-700);
}


.wrapper.mobile .check.pass {
    color: var(--success-700);
    background: var(--success-50);
}
.wrapper.mobile .check.pass .icon {
    color: var(--success-500);
}
