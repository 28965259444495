.month {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 20px;
}

.btns {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

}

.monthWrapper {
    margin-bottom: 20px;
}

.btns button:first-child {
    margin-bottom: 20px;
}

.addWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.mr20 {
    margin-right: 20px;
}
.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
