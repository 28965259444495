.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background: none;
    border-radius: var(--br);
    box-shadow: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: opacity var(--animation), color var(--animation), border var(--animation), background var(--animation), box-shadow var(--animation);
    will-change: opacity, color, border, background, box-shadow;
}

.btn.full {
    width: 100%;
}

.btn.xxl {
    padding: 20px 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}


.btn.xl {
    padding: 16px 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.btn.lg {
    padding: 14px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.btn.md {
    padding: 12px 18px;
}

.btn.xs {
    padding: 8px 14px;
}

.btn[disabled] {
    cursor: not-allowed;
}

.btn.loading {
    cursor: wait;
}

/*PRIMARY*/

.btn.primary {
    color: var(--snow);
    border: 1px solid var(--teal-400);
    box-shadow: var(--teal-light-shadow);
    background: var(--teal-400);
}

.btn.primary:focus, .btn.primary:active {
    box-shadow: var(--teal-shadow);
}

.btn.primary:hover {
    border: 1px solid var(--teal-500);
    background: var(--teal-500);
}

.btn.primary[disabled] {
    color: var(--snow);
    box-shadow: var(--teal-light-shadow);
    border: 1px solid var(--teal-200);
    background: var(--teal-200);
}

/*MINT*/

.btn.mint {
    color: var(--teal-700);
    border: 1px solid var(--teal-100);
    box-shadow: none;
    background: var(--teal-100);
}

.btn.mint:hover {
    border: 1px solid var(--primary-100);
    color: var(--teal-800);
    box-shadow: none;
    background: var(--teal-200);
}
.btn.mint:focus, .btn.mint:active {
    background: var(--teal-100);
    border: 1px solid var(--teal-100);
    box-shadow: var(--teal-medium-shadow);
}


.btn.mint[disabled] {
    color: var(--teal-500);
    box-shadow: none;
    border: 1px solid var(--teal-50);
    background: var(--teal-50);
}

/*BORDERED*/

.btn.bordered {
    color: var(--gray-700);
    box-shadow: var(--gray-light-shadow);
    border: 1px solid var(--gray-300);
    background: var(--snow);
}

.btn.bordered:focus, .btn.bordered:active {
    box-shadow: var(--gray-shadow);
}

.btn.bordered:hover {
    background: var(--gray-50);
}

.btn.bordered[disabled] {
    border: 1px solid var(--gray-200);
    color: var(--gray-300);
    box-shadow: var(--gray-light-shadow);
    background: var(--snow);
}

/*BORDERED*/

.btn.ghost {
    color: var(--gray-600);
    box-shadow: none;
    border: 1px solid transparent;
    background: transparent;
}

.btn.ghost:focus, .btn.ghost:active {
    box-shadow: none;
}

.btn.ghost:hover {
    background: var(--gray-50);
    color: var(--gray-700);
    border: 1px solid var(--gray-50);
}

.btn.ghost[disabled] {
    border: 1px solid transparent;
    color: var(--gray-300);
    box-shadow: none;
    background: transparent;
}

/*DANGER*/

.btn.danger {
    color: var(--snow);
    border: 1px solid var(--red-600);
    box-shadow: var(--red-light-shadow);
    background: var(--red-600);
}

.btn.danger:focus, .btn.danger:active {
    box-shadow: var(--red-shadow);
}

.btn.danger:hover {
    border: 1px solid var(--red-700);
    background: var(--red-700);
}

.btn.danger[disabled] {
    color: var(--snow);
    box-shadow: var(--red-light-shadow);
    border: 1px solid var(--red-200);
    background: var(--red-200);
}

.btn.link {
    padding: 0;
    border: none;
    background: none;
    color: var(--teal-400);
    box-shadow: none;
}

.btn.link:hover, .btn.link:active, .btn.link:focus {
    color: var(--teal-600);
}

.btn.link[disabled], .btn.link[disabled]:hover, .btn.link[disabled]:active {
    color: var(--teal-200);
}


/*ICON*/

.btn.primary .icon, .btn.danger .icon {
    color: var(--snow);
}

.btn.link .icon {
    color: var(--teal-400);
    transition: opacity var(--animation), color var(--animation), border var(--animation), background var(--animation), box-shadow var(--animation);
    will-change: opacity, color, border, background, box-shadow;
}

.btn.link:hover .icon, .btn.link:active .icon, .btn.link:focus .icon {
    color: var(--teal-600);
}

.btn.link[disabled] .icon, .btn.link[disabled]:hover .icon, .btn.link[disabled]:active .icon {
    color: var(--teal-200);
}



.btn.mint .icon {
    color: var(--teal-700)
}



.btn.mint:hover .icon {
    color: var(--teal-800);
}

.btn.mint[disabled] .icon {
    color: var(--teal-500);
}

.btn.bordered .icon {
    color: var(--gray-700);
}

.btn.bordered[disabled] .icon {
    color: var(--gray-300);
}

.btn.ghost .icon {
    color: var(--gray-600);
}

.btn.xxl .icon.preicon {
    margin-right: 14px;
}

.btn .icon.preicon {
    margin-right: 10px;
}

.btn.xxl .icon.posticon {
    margin-left: 14px;
}

.btn .icon.posticon {
    margin-left: 10px;
}

.btn.empty .icon.preicon {
    margin-right: 0;
}

.btn.empty .icon.posticon {
    margin-left: 0;
}
