.text {
    margin-bottom: 20px;
}

.textWrapper {
    width: 100%;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 20px;
}

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
