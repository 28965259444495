.footer {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    padding-bottom: 40px;
}


.personalInfoWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.smallHelp {
    width: 250px;
}

.tabTitle {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.personalInfo {
    background: var(--teal-50);
    border-radius: 8px;
    padding: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.greenText {
    color: var(--teal-500);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
}

.commonFooter {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footerBtns {
    display: flex;
}

.footerBtns button {
    width: 224px;
}

.footerBtns button:not(:last-child) {
    margin-right: 12px;
}

.modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 6px;
}


.steps {
    margin-top: 12px;
    text-align: center;
    color: var(--gray-500);
}
