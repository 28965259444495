.text {
    margin-bottom: 16px;
    color: var(--gray-600);
}

.textWrapper {
    margin-bottom: 24px;
}

.text:last-child {
    margin-bottom: 0;
}

.header {
    margin-top: 20px;
    display: flex;
    margin-bottom: 16px;
    justify-content: flex-end;
}

.dropdownTrigger {
    padding: 0;
}

.header .dropdownWrapper {
    margin-right: 12px;
}

.warning {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.warningText {
    color: inherit;
    font-weight: 600;
}

.warningIcon {
    color: inherit;
    margin-right: 12px;
}

.warningTitle {
    color: var(--warning-700);
    display: flex;
    align-items: flex-start;
}

.uncovered {
    margin-top: 4px;
    padding-left: 52px;
    margin-bottom: 0;
}

.uncovered .li, .uncovered li {
    color: var(--warning-700);
    font-size: 14px;
}

.link {
    margin: 0 3px;
}

.downloadWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.downloadSpinner {
    margin-bottom: 16px;
}
