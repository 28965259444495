.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 12px;
}

.subtitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.subtitle {
    font-weight: 700;
}

.field {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-200);
}

.field.wb {
    border-bottom: none;
}

.field.firstField {
    padding-top: 0;
}

.justField {
    width: 100%;
    padding: 20px 0;
}

.wbField {
    width: 100%;
}

.mb20 {
    margin-bottom: 20px;
}

.fullInput.mb20 {
    margin-bottom: 20px;
}

.lastField {
    padding: 20px 0;
    margin-bottom: 40px;
}

.wbField > div {
    margin-bottom: 20px;
}
.wbField label {
    white-space: pre-wrap;
}


.field.twoInputsField {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.field.twoInputsField .input {
    width: calc(50% - 10px);
    justify-content: space-between;
    margin-bottom: 0;
}
.field.twoInputsField .input > label {
    white-space: pre-wrap;
}

.wbField.bb {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field.last {
    border-bottom: none;
    padding-bottom: 0;
}

.formFooter {
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.fullInput {
    width: 100%;
    margin-bottom: 0;
}

.plRadio {
    padding-left: 24px;
}
