.wrapper {
    width: 640px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.header {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 20px;
}

.field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field.withoutBorder {
    border-bottom: none;
}

.fullInput {
    width: 100%;
}

.field.last {
    border-bottom: none;
    margin-bottom: 0;
}

.field .input {
    width: calc(50% - 10px);
    margin-bottom: 20px;
}

.imageField {
    width: 100%;
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 32px;
    margin-bottom: var(--field-mb);
}

.helpText {
    color: var(--snow);
    font-size: 12px;
    margin-bottom: 8px;
}

.tabTitle {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 30px;
}

.help {
    width: 520px;
}

.smallHelp {
    width: 250px;
}

.mb40 {
    margin-bottom: 40px;
}
