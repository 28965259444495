.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-700);
    white-space: pre-wrap;
    font-family: var(--font);
}

.hint {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font);
    color: var(--gray-600);
}

.childWrapper {
    display: flex;
    align-items: center;
    margin: 6px 0;
}

.errors {
    font-weight: 400;
    font-family: var(--font);
    font-size: 14px;
    height: 20px;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    color: var(--red-500);
}
.errors.fullError {
    white-space: pre-wrap;
    position: relative;
    overflow: auto;
    height: auto;
}

.wrapper .help {
    position: absolute;
    right: -26px;
}

.helpContent {
    white-space: break-spaces;
    min-width: 250px!important;
}

.wrapper .label .help {
    position: relative;
    right: auto;
}
