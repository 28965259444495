.container {
    padding-top: 48px;
}

.title {
    margin-bottom: 4px;
}

.subtitle {
    color: var(--gray-600);
    margin-bottom: 24px;
    width: 60%;
}

.titleWrapper {
    padding-bottom: 0;
}

.wrapper {
    width: 640px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.forgot {
    width: 250px;
    color: var(--snow);
}

.passwordWrapper {
    width: 545px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.header {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 20px;
}

.field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field.withoutBorder {
    border-bottom: none;
}

.fullInput {
    width: 100%;
}

.field.last {
    border-bottom: none;
    margin-bottom: 0;
}

.field .input {
    width: calc(50% - 10px);
    margin-bottom: 20px;
}

.footer {
    display: flex;
    align-items: center;
    margin-top: 26px;
    justify-content: flex-end;
}

.footer button:not(:last-child) {
    margin-right: 12px;
}

.goBackFooter {
    display: flex;
    align-items: center;
    margin-top: 26px;
    justify-content: flex-end;
}

.goBackFooter button:last-child {
    margin-left: 12px;
}

.imageField {
    width: 100%;
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 32px;
    margin-bottom: var(--field-mb);
}

.helpText {
    color: var(--snow);
    font-size: 12px;
    margin-bottom: 8px;
}

.tabTitle {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 30px;
}

.help {
    width: 520px;
}

.smallHelp {
    width: 250px;
}

.mb40 {
    margin-bottom: 40px;
}
