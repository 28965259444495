.table {
    border-collapse: collapse;
    border-spacing: 0;
    border-color: transparent;
    width: 100%;
}

.invisible * {
    opacity: 0;
    pointer-events: none;
}

.wrapper {
    overflow-x: auto;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    margin-bottom: 30px;
}

.rates .wrapper {
    margin-bottom: 0;
}

.rates {
    margin-bottom: 30px;
}

.addRateWrapper {
    display: flex;
    margin-top: 8px;
    justify-content: flex-end;
}

.remove {
    cursor: pointer;
}
.remove.disabled {
    cursor: not-allowed;
    color: var(--gray-300);
}

.remove:not(.disabled):hover {
    color: var(--error-500);
}

.table td {
    padding: 12px 24px;
    color: var(--gray-600);
    font-family: Inter;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 18px;
    background: var(--snow);
    flex: 100%;
}

.table td.grey, span.grey {
    background: var(--gray-50);
}

.table td.primary {
    background: var(--primary-25);
}

.table td.darkgrey {
    background: var(--primary-500);
    color: var(--snow);
}

.table td.parent {
    padding: 0;
}

.table tr td td {
    padding: 12px;
}

.table tr td {
    border-bottom: 1px solid var(--gray-200);
    border-right: 1px solid var(--gray-200);
}

.table td.br {
    border-right: 1px solid var(--gray-200) !important;
}
.table td.bb {
    border-bottom: 1px solid var(--gray-200) !important;
}

.table td.nbb {
    border-bottom: none;
}

.table tr td.empty {
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
}

.parent td:last-child {
    border-right: none;
}

.table td.center {
    text-align: center;
}

.years {
    display: flex;
    flex-direction: row;
    position: relative;
}

.years:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    content: '';
    background: var(--gray-200);
}

.years span {
    width: 50%;
}

.table tr td tr {
    width: 100%;
}

.table td.cellInput {
    padding: 0;
}

.input{
    width: 100%;
    text-align: center;
    height: 100%;
    padding: 12px 24px;
    color: var(--gray-600);
    background: var(--snow);
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    outline-color: var(--teal-500);
}

.input:disabled {
    cursor: not-allowed;
    background: var(--snow);
}
