.wrapper {

}


.field {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-200);
}

.field.last {
    border-bottom: none;
}

.field.twoInputsField, .field.threeInputsField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fullInput {
    width: 100%;
    margin-bottom: 0;
}

.field.twoInputsField .input {
    width: calc(50% - 10px);
    margin-bottom: 0;
}

.text {
    padding: 24px 0 20px;
    color: var(--gray-600);
}

.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subtitle {
    color: var(--gray-600);
    margin-bottom: 12px;
}

.subtitle > div:not(:last-child) {
    margin-bottom: 18px;
}
