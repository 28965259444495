.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    height: 40px;
    min-height: 40px;
    margin-bottom: 20px;
    background: var(--primary-100);
    color: var(--primary-600);
    border: 6px solid var(--primary-50);
}

.iconWrapper i {
    color: inherit;
}

.title {
    margin-bottom: 8px;
    text-align: center;
    color: var(--gray-900);
}

.subtitle {
    color: var(--gray-600);
}

.btn {
    margin-top: 32px;
}
