.wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: var(--snow);
    border: 1px solid var(--gray-200);
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
}

.title {
    padding: 20px 24px;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    line-height: 28px;
    color: var(--gray-900);
    font-family: var(--font);
}

.placeholder {
    display: flex;
    padding: 14px;
    align-items: center;
    justify-content: center;
}

.table {
    font-family: var(--font);
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    border-color: transparent;
}

.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

.table thead tr td {
    padding: 13px 24px;
    font-family: inherit;
    color: var(--gray-600);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background: var(--gray-50);
    white-space: nowrap;
    border-bottom: 1px solid var(--gray-200);
    border-top: 1px solid var(--gray-200);
}

.table tbody tr td {
    padding: 26px 24px;
    font-family: inherit;
    color: var(--gray-900);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 1px solid var(--gray-200);
}

.sort {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: var(--gray-600);
}

.sortIcon {
    font-size: 16px;
    color: inherit;
    margin-left: 4px;
}

.sort.active {
    color: var(--gray-900);
    font-weight: 600;
}

.sortChildWrapper {
    color: inherit;
    font-weight: inherit;
}

.sort.active.asc .sortIcon {
    transform: rotateZ(180deg);
}
