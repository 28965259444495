.title {
    margin-bottom: 32px;
}

.subtitle {
    color: var(--gray-600);
    margin-bottom: 16px;
}

.titleWrapper {
    padding-bottom: 24px;
    padding-top: 52px;
}

.searchBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.searchBtns > button:not(:last-child) {
    margin-right: 12px;
}

.search {
    margin-bottom: 0;
}

.searchField {
    margin-bottom: 0;
    padding-bottom: 0;
}

.dropdownTrigger {
    padding: 0;
}

.searchAutocomplete {
    margin-bottom: 0;
}

.placeholderWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 0;
}

.icon {
    color: var(--gray-600);
    font-size: 20px;
}

.icon.disabled {
    color: var(--gray-300);
}

.tip {
    width: 150px;
    white-space: pre-wrap;
}

.gray {
    color: var(--gray-600);
}

.cell {
    display: flex;
    align-items: center;
}

.appliedListWrapper {
    text-align: left;
}

.appliedList {
    display: flex;
    flex-direction: column;
}

.appliedList a {
    font-size: 14px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.greenDot {
    display: flex;
    background: var(--teal-500);
    padding: 2px 8px;
    min-width: 24px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 16px;
    color: var(--snow);
    font-size: 12px;
    margin-left: 8px
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder {
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rateIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rateIcon:not(:last-child) {
    margin-right: 14px;
}

.rateIcon {
    cursor: pointer;
}

.placeholderTitle {
    text-align: center;
    margin-bottom: 8px;
    margin-top: 20px;
}

.placeholderText {
    text-align: center;
    color: var(--gray-600);
    margin-bottom: 32px;
}

.footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.footer button:not(:last-child) {
    margin-right: 12px;
}


.footer button {
    width: 220px;
}



.warning {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.warningText {
    color: inherit;
    font-weight: 600;
}

.warningIcon {
    color: inherit;
    margin-right: 12px;
}

.warningTitle {
    color: var(--warning-700);
    display: flex;
    align-items: flex-start;
}

.uncovered {
    margin-top: 4px;
    padding-left: 52px;
    margin-bottom: 0;
}

.uncovered .li, .uncovered li {
    color: var(--warning-700);
    font-size: 14px;
}
