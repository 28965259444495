.btn {
    border-radius: 8px;
    border: 0 solid var(--gray-300);
    background: linear-gradient(90deg, #30D5C8 0.06%, #2B619B 99.92%);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
    color: var(--snow);
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    align-items: center;
}

.btn:hover {
    background-size: 140% 100%;
    animation: gradient 1s ease infinite;
}

.link {
    cursor: pointer;
}

.icon {
    margin-left: 8px;
    color: var(--snow);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
