.wrapper {
    display: flex;
    position: relative;
}

.wrapper.hovered:hover .text {
    transform: translateY(-50%) translateX(14px);
    opacity: 1;
}

.wrapper.debounced:hover .text {
    pointer-events: all;
    transform: translateY(-50%) translateX(5px);
}

.wrapper.debounced .text:hover {
    opacity: 1;
    pointer-events: all;
}

.text {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%) translateX(5px);
    background: var(--gray-900);
    border-radius: 8px;
    transition: opacity var(--animation), transform var(--animation);
    will-change: opacity, transform;
    padding: 8px 12px;
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    z-index: 1;
    line-height: 18px;
    text-align: center;
    color: var(--snow);
    white-space: nowrap;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: absolute;
    top: 50%;
    left: 100%;
}

.text:before {
    border-right: 6px solid var(--gray-900);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5px;
}

.wrapper.debounced .text {
    transform: translateY(-50%) translateX(0);
}


.wrapper.top.hovered:hover .text {
    transform: translateX(-50%) translateY(-45px);
    opacity: 1;
}

.wrapper.top .text {
    top: 0;
    transform: translateX(-50%) translateY(-30px);
    left: 50%;
}



.wrapper.top .text.text:before {
    border-top: 6px solid var(--gray-900);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
}

.wrapper.left.hovered:hover .text {
    transform: translateY(-50%) translateX(-14px);
    opacity: 1;
}

.wrapper.left .text {
    transform: translateY(-50%) translateX(-6px);
    right: 100%;
    left: auto;
}



.wrapper.left .text.text:before {
    border-left: 6px solid var(--gray-900);
    border-right: none;
    transform: translateY(-50%);
    left: auto;
    right: -5px;
}
