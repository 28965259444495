.wrapper {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 11;
    height: 100vh;
}

.wrapper.show {
    display: flex;
}

.overlay {
    position: fixed;
    top: 0;
    z-index: 12;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    animation: blur-animate 0.2s;
    justify-content: center;
    overflow: auto;
    margin-bottom: 20px;
}


.modal {
    position: relative;
    z-index: 12;
    outline: none;
    animation: scale-up-center 0.2s;
    display: flex;
    width: 400px;
    background: var(--snow);
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 24px;
    align-items: center;
    flex-direction: column;
}


.wrapper.closing .modal {
    animation: scale-down-center 0.2s;
    animation-fill-mode: forwards;
}
.wrapper.closing {
    animation: opacity-off 0.2s;
    animation-fill-mode: forwards;
}

.title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    text-align: center;

    color: var(--gray-900);
    margin-bottom: 8px;
}

.message {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--gray-600);
}

.footer {
    margin-top: 32px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer button {
    width: calc(50% - 6px);
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    height: 40px;
    min-height: 40px;
    margin-bottom: 20px;
    background: var(--success-100);
    color: var(--success-600);
    border: 6px solid var(--success-50);
}

.icon {
    color: inherit;
}

@keyframes scale-up-center {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

@keyframes scale-down-center {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(0)
    }
}

@keyframes opacity-on {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes opacity-off {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes scale-down-center {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(0)
    }
}


@keyframes blur-animate {
    0% {
        backdrop-filter: blur(0);
    }
    100% {
        backdrop-filter: blur(8px);
    }
}

.chboxWrapper {
    margin-top: 20px;
}

.chbox :global .checkbox-label {
    margin-bottom: 0;
    padding-bottom: 0;
}
