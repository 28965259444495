.subtitle {
    color: var(--gray-900);
    margin-bottom: 24px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}
