.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}
.gray {
    color: var(--gray-600);
    font-weight: 400;
}

.tables {
    display: flex;
    flex-direction: column;
}

.table {
    margin-bottom: 48px;
}

.mb16 {
    margin-bottom: 16px;
}

.mb20 {
    margin-bottom: 20px;
}

.green {
    padding: 48px 72px;
    background: var(--teal-50);
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    flex-direction: column;
}

.greenText {
    text-align: center;
    color: var(--teal-500);
}

.greenText:not(:last-child) {
    margin-bottom: 16px;
}
