.mb16 {
    margin-bottom: 16px;
}
.mb20 {
    margin-bottom: 20px;
}

.mb0 {
    margin-bottom: 0;
}

.pb0 {
    padding-bottom: 0;
}

.placeholder {
    border-radius: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    margin-bottom: 20px;
}

.placeholderTexts p {
    color: var(--warning-700);
}
.placeholderTexts p:not(:last-child) {
    margin-bottom: 5px;
}

.placeholderIcon {
    color: var(--warning-600);
    margin-right: 12px;
    font-size: 24px!important;
}

.notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 52px;
}

.notificationIcon {
    margin-right: 10px;
}
