.form {

}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
