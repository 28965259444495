.lines {
    display: flex;
    margin-top: 14px;
    flex-direction: column;
    width: 100%;
}

.mb0 {
    margin-bottom: 0!important;
}

.mb0 > div {
    margin-bottom: 0;
}

.mt0 {
    margin-top: 0;
}

.line {
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
}

.checkbox {
    white-space: nowrap;
    width: 15%;
}

.input {
    margin-left: 8px;
    margin-bottom: 0;
    width: 35%;
}

.input.longInput {
    width: 50%;
}
