.wrapper {
    display: flex;
    pointer-events: none;
    opacity: 0;
    z-index: 11;
    position: fixed;
    flex-direction: column;
    border: 1px solid var(--gray-200);
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    background: var(--snow);
}

.wrapper.show {
    pointer-events: all;
    opacity: 1;
}

.list {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    flex-direction: column;
}

.list li {
    padding: 16px;
    color: var(--gray-700);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 20px;
    font-family: var(--font);
}

.list li.withInnerList {
    position: relative;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.arrow {
    color: var(--gray-700);
    font-size: 20px;
    margin-left: 16px;
}

.list li.withInnerList.disabled .arrow {
    color: var(--gray-400);
}

.list li .list.innerList {
    position: absolute;
    display: none;
    left: 100%;
    background: var(--snow);
    top: 0;
    flex-direction: column;
    border: 1px solid var(--gray-200);
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    background: var(--snow);
}

.list li .list.innerList.horizontal {
    bottom: 0;
    top: auto;
}

.list li.withInnerList:hover > .list.innerList {
    display: flex;
}
.list li.withInnerList.disabled:hover > .list.innerList {
    display: none;
}

.list.innerList li {
    white-space: nowrap;
}

.list li:first-child {
    border-radius: 8px 8px 0 0;
}

.list li:last-child {
    border-radius: 0 0 8px 8px;
}

.list li.divider {
    border-bottom: 1px solid var(--gray-200);
}

.list li:hover {
    background: var(--gray-50);
}

.list li.disabled:hover {
    background: none;
}

.list li.disabled {
    color: var(--gray-400);
    cursor: not-allowed;
}

.icon_disabled {
    color: var(--gray-400)!important;
    cursor: not-allowed;
}

.icon {
    margin-right: 6px;
    color: var(--gray-700);
}
