.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.placeholder {
    padding: 14px 10px 12px 10px;
    text-align: center;
}

.remove {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
}

.list {
    list-style: none;
    padding: 0 0 0 12px;
}

.year {
    display: flex;
    box-shadow: none;
    padding: 10px 10px 10px 26px;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
}

.year.active {
    background: var(--gray-50);
}

.year:hover {
    background: var(--gray-50);
}

.unitWrapper {
    display: flex;
    flex-direction: column;
}

.unit {
    border: none;
    background: none;
    display: flex;
    box-shadow: none;
    border-radius: 6px;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
    padding: 0;
}

.totals {
    padding: 8px 10px;
    min-height: 48px;
    border: none;
    background: none;
    font-size: 14px;
    font-family: var(--font);
    color: var(--gray-900);
    font-weight: 700;
    cursor: pointer;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.totals:hover {
    background: var(--primary-200);
}

.totals.active {
    background: var(--primary-700);
    color: var(--snow);
}

.unit.active {
    background: var(--gray-50);
}

.unit .name {
    color: inherit;
    font-size: inherit;
    width: 100%;
    padding: 10px 26px;
    text-align: left;
    max-width: calc(100% - 84px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.unit:before {
    position: absolute;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 50%;
    display: inline-block;
    background: var(--teal-500);
    top: 50%;
    transform: translateY(-50%);
    left: 9px;
}


.unit.active {
    background: var(--gray-50);
}

.unit:hover {
    background: var(--gray-50);
}

.title {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list {
    list-style: none;
    padding: 0 0 0 12px;
}

.year {
    display: flex;
    box-shadow: none;
    padding: 10px 10px 10px 26px;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    transition: background var(--animation);
    cursor: pointer;
    align-items: center;
    line-height: 24px;
    font-family: var(--font);
    color: var(--gray-900);
    position: relative;
    margin-bottom: 4px;
}

.year.active {
    background: var(--gray-50);
}

.year:hover {
    background: var(--gray-50);
}
