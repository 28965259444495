.overlay {
    position: fixed;
    top: 0;
    z-index: 12;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    margin-bottom: 20px;
}

.content {
    position: relative;
    outline: none;
    display: flex;
    width: 680px;
    max-height: 90vh;
    background: var(--snow);
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 24px 0;
    flex-direction: column;
}

.content.wide {
    width: 800px;
}


.content.fb {
    width: 80vw;
    max-width: 1200px;
}

.content.small {
    width: 450px;
}

.content.videoContent {
    background: none;
    padding: 0;
    width: 80%;
}

.confirmation.content {
    align-items: center;
    width: 400px;
}

:global .ReactModal__Body--open {
    overflow: hidden;
}

.title {
    font-weight: 600;
    font-family: var(--font);
    margin: 0 0 4px 0;
    font-size: 30px;
    line-height: 38px;
    padding: 0 24px;
    color: var(--gray-900);
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    font-family: var(--font);
    line-height: 24px;
    padding: 0 24px;
    margin-bottom: 32px;
    color: var(--gray-600);
}

.subtitle .subtitleField:not(:last-child) {
    margin-bottom: 18px;
}

.subtitle.center {
    text-align: center;
}

.title.onlyTitle {
    margin-bottom: 20px;
}

.title.center.onlyTitle {
    margin-bottom: 20px;
    text-align: center;
}

.contentWrapper {
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
}

.cancelIcon {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.cancelIcon.withoutTitle {
    top: 26px;
    right: 26px;
}

.cancelIcon.cancelVideoBtn {
    top: -25px;
    right: -40px;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    align-self: center;
    height: 40px;
    min-height: 40px;
    margin-bottom: 20px;
}

.iconWrapper.success {
    background: var(--success-100);
    color: var(--success-600);
    border: 6px solid var(--success-50);
}

.iconWrapper.warning {
    background: var(--warning-100);
    color: var(--warning-600);
    border: 6px solid var(--warning-50);
}

.iconWrapper.error {
    background: var(--error-100);
    color: var(--error-600);
    border: 6px solid var(--error-50);
}

.iconWrapper.info, .iconWrapper.default {
    background: var(--primary-100);
    color: var(--primary-600);
    transform: rotateZ(180deg);
    border: 6px solid var(--primary-50);
}
.icon {
    color: inherit;
}

.video {
    width: 100%;
    border-radius: 12px;
}
