.anchor {
    padding: 8px 10px;
    min-height: 40px;
    border: none;
    background: none;
    font-size: 14px;
    font-family: var(--font);
    color: var(--gray-900);
    font-weight: 700;
    cursor: pointer;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.anchor.disabled {
    cursor: not-allowed;
}

.anchor.thin {
    font-weight: 400;
}

.anchor:hover {
    background: var(--primary-200);
}

.total {
    margin-left: 8px;
}

.anchor.active {
    background: var(--primary-700);
    color: var(--snow);
}

.anchor.asFolder {
    padding-left: 34px;
    font-weight: 500;
    color: var(--gray-700);
    justify-content: flex-start;
}

.anchor.blocked {
    color: var(--gray-400);
    cursor: not-allowed;
}
.anchor.blocked:hover {
    background: none;
    color: var(--gray-400);
}

.active.blocked .icon {
    color: var(--gray-400) !important;
}

.lock {
    position: absolute;
    right: 0;
    color: var(--gray-400) !important;
    top: 50%;
    transform: translateY(-50%);
}


.anchor.asFolder.asFolderWithIcon {
    padding-left: 8px;
}

.anchor.asFolder.active, .anchor.asFolder.active .icon {
    color: var(--snow);
}

.anchor.asFolder .icon {
    color: var(--gray-500);
    margin-right: 8px;
}
