
.warning {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
}

.warning.bordered {
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);
    padding: 16px;
}

.cross {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px!important;
    cursor: pointer;
    color: var(--warning-700);
}

.warningText {
    color: inherit;
    font-weight: 600;
}

.warningIcon {
    color: inherit;
    margin-right: 12px;
}

.warningTitle {
    color: var(--warning-700);
    display: flex;
    align-items: flex-start;
}

.uncovered {
    margin-top: 4px;
    padding-left: 52px;
    margin-bottom: 0;
}

.uncovered .li, .uncovered li {
    color: var(--warning-700);
    font-size: 14px;
}

.link {
    margin: 0 3px;
}
