.wrapper {
    position: fixed;
    height: calc(100vh - var(--header) - var(--line));
    width: 360px;
    background: var(--snow);
    top: calc(var(--header) + var(--line));
    right: 0;
    padding: 32px 24px;
    border-left: 1px solid var(--gray-200);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.tabs {
    flex: 1 100%;
}

.listWrapper {
    justify-content: center;
}


.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sectionHeader button {
    white-space: nowrap;
}
.sectionHeader button:first-child {
    padding-left: 0;
}
.sectionHeader button:last-child {
    padding-right: 0;
}

.sectionPlaceholder {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.mt40 {
    margin-top: 40px;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1 100%;
    overflow-y: auto;
    min-height: calc(100% - 128px);
    max-height: calc(100vh - var(--header) - var(--line) - 142px);
    height: calc(100vh - var(--header) - var(--line) - 142px);
}

.emptySection {
    align-items: center;
    justify-content: center;
}

.placeholderText {
    text-align: center;
    color: var(--gray-600);
    margin-top: 20px;
}



.tabContentWrapper {
    padding-top: 8px;
    height: 100%;
}

.tabContentWrapper > div {
    height: 100%;
}

.sectionFooter {
    padding: 20px 0;
}

.section {
    height: 100%;
    flex: 1 100%;
    display: flex;
    flex-direction: column;
}

.footer {
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    border-top: 1px solid var(--gray-200);
}

.info {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-200);
}

.field {
    color: var(--gray-600);
    margin-bottom: 12px;
}

.field.withPadding {
    padding-left: 20px;
}

.field.nsfField {
    position: relative;
    padding-left: 20px;
}

.field.nsfField:before {
    position: absolute;
    top: 7px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    content: '';
    display: inline-block;
    background: var(--teal-500);
}


.notifyFooter {
    display: flex;
    justify-content: space-between;
}

.notifyFooter button {
    width: calc(50% - 6px);
}

.notifyTitle {
    margin-bottom: 20px;
    text-align: center;
    line-height: 28px;
    font-family: var(--font);
    font-weight: 600;
}
