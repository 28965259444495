.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 12px;
}

.subtitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.subtitle {
    display: block;
    width: 100%;
}
.container {
    padding-top: var(--budget-bar-small);
    transition: padding-top var(--animation);
}

.container.withBar {
    padding-top: var(--budget-bar-big);
}

.barWrapper {
    height: var(--budget-bar-small);
    background: var(--snow);
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    transition: height var(--animation);
}

.barWrapper.big {
    height: auto;
}

.rates {
    margin-top: 28px;
}

.collapse {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer;
    font-family: var(--font);
    font-size: 14px;
    color: var(--teal-400);
    font-weight: 600;
}

.header {
    display: flex;
    justify-content: flex-end;
}

.collapseIcon {
    margin-left: 8px;
    transition: transform var(--animation);
    color: var(--gray-500);
}

.barWrapper.big .collapse .collapseIcon {
    transform: rotateZ(-90deg)
}

.barWrapper .bar {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.bar {
    transition: opacity var(--animation);
    height: 76px;
    padding-top: 24px;
    box-sizing: border-box;
}

.barWrapper.big .bar {
    opacity: 1;
    pointer-events: all;
    height: auto;
    visibility: visible;
}

.trigger {
    padding: 0;
}

.dropdownToggler {
    padding: 8px!important;
    width: 34px;
}

.lineWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.line {
    height: 20px;
    width: 100%;
    margin-right: 25px;
    flex: 1 100%;
    border-radius: 4px;
    background: var(--gray-200);
    display: flex;
    position: relative;
}

.placeholder {
    position: absolute;
    bottom: 100%;
    left: 50%;
    color: var(--gray-700);
    font-family: var(--font);
    font-size: 14px;
    font-weight: 500;
    transform: translateX(-50%) translateY(-2px);
}

.subline {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 4px;
    transition: width var(--animation);
}

.sublineWrapper {

}

.sublineWrapper:not(:first-child) {
    transform: scaleX(105%);
}

.sublineWrapper:not(:first-child) .sublineText {
    transform: translateX(-50%) translateY(-2px) scaleX(95%);
}

.sublineText {
    position: absolute;
    bottom: 100%;
    left: 50%;
    color: var(--gray-700);
    font-family: var(--font);
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    transform: translateX(-50%) translateY(-2px);
}


.legendsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: calc(34px + 25px);
}

.legends {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.legend {
    display: flex;
    align-items: center;
    margin-right: 12px;
    color: var(--gray-700, #344054);
    font-family: var(--font);
    font-size: 14px;
}

.empty {
    display: flex;
    align-items: center;
    margin-right: 12px;
    color: var(--gray-600, #344054);
    font-family: var(--font);
    font-size: 14px;
}

.emptyLink {
    margin: 0 4px;
}
.emptyIcon {
    color: var(--teal-500);
    margin-right: 12px;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
}

.dot.teal, .subline.teal {
    background: var(--teal-400);
}
.dot.red, .subline.red {
    background: var(--error-500);
}
.dot.primary, .subline.primary {
    background: var(--primary-700);
}

.budget {
    color: var(--gray-900, #101828);
    font-family: var(--font);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.overIconTip {
    z-index: 5;
}

.budget:last-child {
    margin-left: 24px;
}

.budgets {
    display: flex;
    flex-direction: row;
}

.budget.over {
    color: var(--error-600);
}

.overIcon {
    color: var(--error-600);
    margin-right: 4px;
}

