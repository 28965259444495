.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.footer.oneBtn {
    justify-content: center;
}

.footer button {
    width: calc(50% - 6px);
}

.title {
    color: var(--gray-900);
    margin-bottom: 8px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    line-height: 28px;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: var(--gray-600)
}

.textField:not(:last-child) {
    margin-bottom: 16px;
}

.formWrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formWrapper.bottom {
    margin-top: 32px;
    margin-bottom: 0;
}

.formWrapper.bottom  .input {
    margin: 0;
    padding: 0;
}

.input {
    width: 100%;
    margin-bottom: 0;
}

.body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.body.plain {
    margin-top: 40px;
}

.closing {

}
