.btn {
    border: none;
    background: none;
    display: flex;
    box-shadow: none;
    padding: 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    transition: background var(--animation);
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
    line-height: 20px;
    font-family: var(--font);
    align-items: center;
    position: relative;
    color: var(--gray-700);
}

.btn.blocked {
    color: var(--gray-400);
    cursor: not-allowed;
}
.btn.blocked:hover {
    background: none;
    color: var(--gray-400);
}

.btn.blocked .icon {
    color: var(--gray-400);
}

.icon.delete {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 8px;
    transform: translateY(-50%);
}

.custom {
}

.custom.withDelete {
    right: 24px;
}

.label {
    flex: 1 100%;
    text-align: left;
}

.wrapper.open > .btn .copy, .wrapper.open > .btn .delete, .wrapper:hover > .btn .delete, .wrapper:hover > .btn .copy {
    display: flex;
}

.copy {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 30px;
    transform: translateY(-50%);
}


.btn[disabled] {
    cursor: not-allowed;
}

.childWrapper {
    padding-left: 24px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.childWrapper:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: calc(100% - 16px);
    background: var(--gray-200);
    position: absolute;
    top: 8px;
    left: 14px;
}

.childWrapper .childWrapper {
    padding-left: 24px;
}

.btnLink {
    width: 100%;
}

.btnLink .btn {
    width: 100%;
}

.wrapper {
    display: flex;
    margin-bottom: 4px;
    flex-direction: column;
}

.icon {
    color: var(--gray-500);
}

.icon.copy, .icon.delete {
    display: none;
}

.chevron {
    margin-right: 8px;
}

.wrapper.open > .btn .chevron {
    transform: rotateZ(90deg);
}

.wrapper.primeOpen > .btn .icon {
    color: var(--snow);
}

.wrapper.primeOpen > .btn {
    background: var(--primary-700);
    color: var(--snow);

}

.wrapper.primeOpen > .btnLink .btn {
    background: var(--primary-700);
    color: var(--snow);
}

.btn:hover {
    background: var(--primary-200);
}

.btn[disabled]:hover {
    background: none;
}

.tip {
    width: 100%;
}


.tip .btn {
    width: 100%;
}

.delete:hover {
    color: var(--red-500) !important;
}
