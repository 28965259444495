.wrapper {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    position: relative;
}

.side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    min-height: 100vh;
}

.side.leftSide {
    position: relative;
}

.slideImage {
    height: 100%;
}

.carouselWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.carouselWrapper :global .react-multi-carousel-list, .carouselWrapper :global .react-multi-carousel-track {
    height: 100%;
}

.divider {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-500);
    position: relative;
}

.divider:after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 45%;
    height: 1px;
    content: '';
    display: inline-block;
    background: var(--gray-200);
}

.social {
    margin-bottom: 8px;
    width: 100%;
}

.google {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: center;
}

.socialIcon {
    height: 24px;
    margin-right: 12px;
}

.divider:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 45%;
    height: 1px;
    content: '';
    display: inline-block;
    background: var(--gray-200);
}

.logo {
    width: 210px;
    position: absolute;
    top: 48px;
    left: 48px;
}

.b {
    position: absolute;
    left: -20px;
    bottom: 0;
    z-index: 0;
}

.year {
    color: var(--gray-300);
    position: absolute;
    z-index: 1;
    bottom: 32px;
    left: 32px;
}


.side.rightSide {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.form {
    width: 50%;
    min-width: 300px;
    max-width: 360px;
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 32px;
    font-weight: 600;
}

.checkboxAndLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.checkboxAndLink a {
    white-space: nowrap;
}

.checkbox {
    margin-bottom: 0;
}

.rememberCheckbox > div {
    margin-bottom: 0!important;
    padding-bottom: 0!important;
}

.signUpLinkWrap {
    margin-top: 32px;
    text-align: center;
}

.mobileLogo {
    display: none;
    width: 210px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
}


.footer {
    color: var(--gray-600);
    display: none;
    padding: 32px;
}


.message {
    display: none;
    margin-right: 16px;
    margin-left: 16px;
}

@media all and (max-width: 767px) {
    .side.leftSide {
        display: none;
    }
    .b {
        display: none;
    }


    .message {
        display: flex;
    }

    .form {
        width: 100%;
        max-width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .mobileLogo {
        display: block;
        left: 32px;
        top: 32px;
        transform: none;
    }

    .rightSide {
        width: 100%;
        min-height: 0!important;
        flex: 1;
        margin-top: 150px;
        justify-content: flex-start!important;
    }

    .footer {
        display: block;
    }

    .wrapper {
        flex-direction: column;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .side.leftSide {
        display: none;
    }

    .mobileLogo {
        display: block;
        left: 32px;
        top: 32px;
        transform: none;
    }

    .wrapper {
        flex-direction: column;
    }

    .message {
        display: flex;
    }
    .side.rightSide {
        width: 100%;
        margin-top: 150px;
        flex: 1;
        justify-content: flex-start!important;
        min-height: 0!important;

    }

    .footer {
        display: block;
    }
}
