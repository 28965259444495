.wrapper {
    width: 100%;
}

.resendText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.resendText button {
    margin-left: 4px;
}
