.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 12px;
}

.toggler {
    margin-bottom: 0!important;
}

.subtitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.btnWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.help {
    width: 300px!important;
}

.subtitle {
    font-weight: 700;
}


