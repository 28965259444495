.wrapper {
    width: 100%;
    display: flex;
    margin-bottom: var(--field-mb);
    flex-direction: column;
}

.dndArea {
    display: flex;
    width: 100%;
    background: var(--snow);
    padding: 16px 24px;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    transition: background var(--animation);
    will-change: transition;
}

.inputAndTrashWrapper {
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
}

.trash {
    margin-left: 4px;
    cursor: pointer;
}

.dndArea.loading {
    cursor: progress;
}

.dndArea.canDrop {
    background: var(--primary-25);
}

.dndArea.canDrop .iconWrapper {
    background: var(--primary-100);
    border: 6px solid var(--primary-50);
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background var(--animation);
    will-change: transition;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    height: 40px;
    min-height: 40px;
    margin-bottom: 12px;
    background: var(--gray-100);
    border: 6px solid var(--gray-50);
}

.textField {
    margin-bottom: 4px;
    color: var(--gray-600);
    text-align: center;
}

.inputFileWrapper {
    border: none;
    outline: none;
    background: none;
    color: var(--teal-400);
    font-family: var(--font);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.inputFile {
    display: none;
}

.dndArea.disabled {
    background: var(--gray-50);
    cursor: not-allowed;
}

.dndArea.disabled .inputFileWrapper {
    color: var(--gray-200);
    cursor: not-allowed;
}

.dndArea.hasError {
    border: 1px solid var(--error-300);
    background: var(--error-25);
}

.dndArea.hasError .iconWrapper {
    background: var(--error-100);
    border: 6px solid var(--error-50);
}

.dndArea.hasError .textField, .dndArea.hasError .inputFileWrapper, .dndArea.hasError .trash {
    color: var(--error-700);
}

.progressWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 12px;
}

.progress {
    height: 8px;
    flex: 1 100%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background: var(--gray-200);
}

.bar {
    position: absolute;
    height: 100%;
    border-radius: 4px;
    background: var(--teal-500);
    transition: width var(--animation);
}

.progressText {
    font-weight: 500;
    font-size: 14px;
    margin-left: 12px;
    font-family: var(--font);
    line-height: 20px;
    color: var(--gray-700);
}
