.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contentWrapper {
    display: flex;
    width: 100%;
    padding-top: 48px;
    flex-direction: column;
    border-top: 1px solid var(--gray-200);
}

.contentWrapper.smallPadding {
    padding-top: 32px;
}

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
}

.tabs.withAction {
    overflow-x: auto;
}

.tabs.full .tab {
    flex: 1;
}

.tab {
    padding: 12px;
    border: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background var(--animation);
    will-change: background;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    justify-content: center;
    color: var(--gray-500);
    white-space: nowrap;
    height: 44px;
}

.tab.fullTab {
    max-width: 100%;
}

.tab:not(.fullTab) .tabLabel {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.delete {
    margin-left: 6px;
}

.action {
    padding: 11px!important;
    height: 44px;
    border-radius: 0!important;
    white-space: nowrap;
}

.tab:last-child {
    margin-right: 0;
}

.tab.active {
    background: var(--primary-100);
    position: relative;
    color: var(--primary-700);
}

.tab.active:after {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    left: 0;
    display: inline-block;
    content: '';
    background: var(--primary-700);
}

.tab.disabled {
    color: var(--gray-300);
    cursor: not-allowed;
}
