.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    flex: 1 100%;
}

.footer button:first-child {
    margin-right: 12px;
}

.footer button:last-child {
    margin-right: 0;
}

.verifyBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.green {
    color: var(--success-500) !important;
}
.red {
    color: var(--error-500) !important;
}

.carrierRedHelp {
    width: 200px!important;
}

.text {
    margin-bottom: 20px;
}
