.mb16 {
    margin-bottom: 16px;
}

.info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--gray-700);
}

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
}
.gray {
    color: var(--gray-600);
    font-weight: 400;
}

.tables {
    display: flex;
    flex-direction: column;
}

.table {
    margin-bottom: 48px;
}

.textLink {
    color: var(--teal-500);
    text-decoration: underline;
}

.li {
    margin-bottom: 8px;
}

.list {
    padding-left: 18px;
    margin-bottom: 16px;
}

.list li::marker {
    color: var(--teal-500);
}

.mb16 {
    margin-bottom: 16px;
}

.mb20 {
    margin-bottom: 20px;
}
