.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper.full {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
}

.wrapper img {
    /*-webkit-animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;*/
    /*-moz-animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;*/
    /*animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;*/
    width: 58px;
    height: 58px;
}

.wrapper.small img {
    width: 20px;
    height: 20px;
}


@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
