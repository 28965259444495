.wrapper {
    padding: 16px;
    border-radius: 8px;
    background: var(--warning-25);
    display: none;
    flex-direction: column;
    margin-top: 60px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.close {
    color: var(--warning-500);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

@media  all and (max-width: 1024px) {
    .wrapper {
        display: flex;
    }
}

.title {
    color: var(--warning-700);
    margin-bottom: 8px;
}


.text {
    color: var(--warning-700);
}
.text a {
    color: var(--teal-500);
}
