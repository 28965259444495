.footer {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    flex: 1 100%;
}

.footer button:first-child {
    margin-right: 12px;
}

.footer button:last-child {
    margin-right: 0;
}

.text.mb60 {
    margin-bottom: 40px;
}

.text {
    margin-bottom: 20px;
}
