.wrapper {
    width: 100%;
}

.field {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-200);
}

.field.last {
    border-bottom: none;
}

.semilast {
    padding-bottom: 0;
    border-bottom: none;
}

.field.pb0 {
    padding-bottom: 0!important;
    border-bottom: none!important;
}

.help {
    width: 250px!important;
}

.centerTitle {
    text-align: center;
    margin-bottom: 6px;
}


.center {
    text-align: center;
}

.formFooter {
    margin-top: 34px;
    display: flex;
    justify-content: center;
}


.field.twoInputsField, .field.threeInputsField, .field .threeInputsField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.field .twoInputsField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fullInput {
    width: 100%;
    margin-bottom: 0;
}


.title {
    margin-bottom: 12px;
}

.field.twoInputsField > .input, .field.twoInputsField > .tip, .field .twoInputsField > .input, .field .twoInputsField > .tip {
    width: calc(50% - 10px);
    margin-bottom: 0;
}

.tip .input {
    width: 100%;
    margin-bottom: 0;
}

.tipText {
    width: 240px;
    white-space: pre-wrap;
}

.field.twoInputsField.fieldWithHelps .input {
    width: calc(50% - 20px);
    margin-bottom: 0;
}

.field.threeInputsField .input, .field .threeInputsField .input {
    width: calc(33.33% - 10px);
    margin-bottom: 0;
}

.typeHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    line-height: initial;
    font-size: 14px;
}

.linkBtn {
    color: var(--teal-400);
    cursor: pointer;
    font-weight: 600;
}


.linkBtn:hover, .linkBtn:active, .linkBtn:focus {
    color: var(--teal-600);
}

.typeHint.wrap {
    white-space: pre-wrap;
}

.typeHint.wrap .hintText {
    display: inline;
}

.typeHint.wrap .hintText button {
    display: inline;
}

.hintIcon {
    color: var(--teal-400);
    margin-right: 8px;
}

.typeHint button {
    margin-left: 8px;
    white-space: initial;
}

.notifyFooter {
    display: flex;
    justify-content: space-between;
}

.yearsFooter {
    display: flex;
    justify-content: center;
}

.notifyFooter button {
    width: calc(50% - 6px);
}

.notifyTitle {
    margin-bottom: 20px;
    text-align: center;
    line-height: 28px;
    font-family: var(--font);
    font-weight: 600;
}

.yearsTitle {
    margin-bottom: 8px;
    font-family: var(--font);
    line-height: 28px;
    color: var(--gray-900);
    font-weight: 600;
}

.yearsSubTitle {
    margin-bottom: 20px;
    line-height: 20px;
    color: var(--gray-600);
    font-family: var(--font);
    font-weight: 400;
}

.glow {
    position: relative;
}

.glow:before {
    pointer-events: none;
    position: absolute;
    top: -12px;
    left: -14px;
    width: calc(100% + 28px);
    height: calc(100% + 24px);
    border: 2px solid var(--teal-400);
    border-radius: 8px;
    display: inline-block;
    content: '';
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 2000ms;
    animation-delay: 300ms;
    animation-play-state: running;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.nsfInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-200);
}

.nsfHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
}

.nsfHeader .input {
    width: calc(50% - 20px);
    margin-bottom: 0;
}

.nsfBody {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 0px;
}

.nsfBody .npsInput {
    width: calc(50% - 20px);
    margin-bottom: 0;
}
.nsfBody .npsInput .input {
    width: 100%;
}

.title.subtitle {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 20px;
    margin-top: 12px;
}
