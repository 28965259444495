.wrapper {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 8px;
    border-radius: 6px;
    background: var(--primary-50);
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--gray-900);
    transition: background var(--animation);
    cursor: pointer;
    min-height: 40px;
    box-sizing: border-box;
}

.chevron {
    margin-right: 8px;
    color: var(--gray-500);
    cursor: pointer;
}

.wrapper.active:not(.closedActive) > .header .chevron {
    transform: rotateZ(90deg);
    color: var(--snow);
}

.wrapper.open:not(.active) > .header .chevron, .wrapper.open:not(.closedActive) > .header .chevron {
    transform: rotateZ(90deg);
}

.wrapper.open:not(.active) > .header, .wrapper.open:not(.closedActive) > .header {
    border-radius: 6px 6px 0 0;
}


.wrapper.open:not(.active) > .body , .wrapper.open:not(.closedActive) > .body {
    border: 1px solid var(--primary-50);
    border-top: none;
}

.wrapper.closedActive > .header .chevron {
    color: var(--snow);
}

.wrapper.active > .header .icon, .wrapper.closedActive > .header .icon {
    color: var(--snow);
}

.icon.custom {
    margin-right: 8px;
}

.wrapper.active > .header, .wrapper.closedActive > .header {
    background: var(--primary-700);
    color: var(--snow);
}

.wrapper.active:not(.closedActive) > .header {
    border-radius: 6px 6px 0 0 ;
}

.wrapper.active.asLink > .header {
    border-radius: 6px;
}

.wrapper.active > .header .role, .wrapper.closedActive > .header .role {
    border: 1.5px solid var(--snow);
    color: var(--snow);
}

.titles {
    flex: 1 100%;
}

.header:hover {
    background: var(--primary-200);
}

.role {
    margin-top: 4px;
    padding: 2px 8px;
    border: 1.5px solid var(--primary-600);
    font-size: 12px;
    margin-right: 4px;
    border-radius: 16px;
    font-family: var(--font);
    color: var(--primary-700);
    max-width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.title {
    font-size: 14px;
    color: inherit;
    font-weight: 500;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: flex;
    padding: 3px 0 0;
    border-top: none;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
}

.wrapper.active > .body {
    border: 1.5px solid var(--primary-700) !important;
}

.link {
    width: 100%;
    color: var(--gray-900);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 30px;
    transition: background var(--animation);
}

.link:hover {
    background: var(--primary-200);
}

.linkWrapper:hover:last-child .link {
    border-radius: 0 0 5px 5px;
}

.link.active {
    background: var(--primary-700);
    color: var(--snow);
}
.linkWrapper.active:last-child .link {
    border-radius: 0 0 3px 3px!important;
}

.link.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
}

.icon.delete {
    display: none;
}

.wrapper:hover .icon.delete, .wrapper.active .icon.delete {
    display: flex;
}

.icon.delete:hover {
    color: var(--red-500) !important;
}
