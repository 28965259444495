.subtitle {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtitle button {
    margin-left: 8px;
}
