.wrapper {

}

.processing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.processingText {
    margin-bottom: 20px;
    text-align: center;
}
