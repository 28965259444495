.wrapper {

}

.slider {
    position: relative;
    padding-bottom: 70px;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.control {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 1px solid var(--gray-200);
    background: rgba(255, 255, 255, 0.90);
    transition: background var(--animation);
    cursor: pointer;
}

.control:hover {
    background: var(--gray-200);
}

.control:first-child {
    margin-right: 8px;
}

.control:last-child {
    margin-left: 8px;
}

.controlIcon {
    color: var(--gray-500);
}
